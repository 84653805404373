import React, { useEffect, useState } from 'react';
import filterTableIcon from '../../assets/filterTableIcon.png';
import './Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export default function Referral() {
  const navigate = useNavigate();
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDataResult, setTotalDataResult] = useState(0);
  const [loader, setLoader] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [refFilterData, setRefFilterData] = useState([]);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [refHistory, setRefHistory] = useState([]);

  const handlePageNumber = (pageNo) => {
    if (pageNo >= 1 && pageNo <= totalPages) {
      setActivePageNo(pageNo);
      getReferralData(pageNo);
    }
  };
  
  const getReferralData = async (pageNo) => {
    setLoader(true);
    try {
      const { data } = await axios.get(`${URLS.getAllReferral}/all`, {
        params: {
          pageSize: '10',
          page: pageNo,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      let array =[], datata = data?.data?.result;
      for(var key in datata){
        let col = datata[key];
        let obj = {
          walletaddress: col?.walletaddress,
          status: col?.status === 'approve' ? "Approved": col?.status === 'reject' ? "Rejected" : "Pending",
          username: col?.username,
          _id: col?._id
        }
        array.push(obj);
      }
      setReferralData(array);
      setTotalPages(Math.ceil(data?.data?.totalResult / data?.data?.pageSize));
      setTotalDataResult(data?.data?.totalResult)
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleSorting = async (order) => {
    setLoader(true);
    try {
      const { data } = await axios.get(`${URLS.getAllReferral}/${order}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      setRefFilterData(data?.data?.result || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleRedirectToDetailsPage = (id) => {
    navigate(`/affiliate/referral-requests-details`, { state: id });
  };

  useEffect(() => {
    getReferralData(1);
  }, []);

  useEffect(() => {
    setRefFilterData(referralData);
  }, [referralData]);

  const handleFilterTable = (e) => {
    const filterText = e.target.value.toLowerCase();
    if (filterText === '') {
      setRefFilterData(referralData);
    } else {
      const filteredData = referralData.filter(({ walletaddress, username, status }) =>
        walletaddress.toLowerCase().includes(filterText) ||
        username.toLowerCase().includes(filterText) ||
        status.toLowerCase().includes(filterText)
      );
      setRefFilterData(filteredData);
    }
  };

  if(loader) return <Loader />;
  return (
    <>
      <div className="referral_container">
        <div className="referralTable">
          <h1>Referral Application</h1>
          <div className='d-flex justify-content-between flex-wrap gap-2 align-items-center'>
            <div className="filtertablebtn dropdown drop-down-box">
              <button type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="true" className="filter_btn w-100 h-100">
                <img src={filterTableIcon} alt="filterTable Icon" />
                Filter
              </button>
              <ul className="dropdown-menu filter_drop_box" aria-labelledby="dropdownMenu2">
                <li><button onClick={() => handleSorting('all')} className="dropdown-item" type="button">All</button></li>
                <li><button onClick={() => handleSorting('approved')} className="dropdown-item" type="button">Approved</button></li>
                <li><button onClick={() => handleSorting('pending')} className="dropdown-item" type="button">Pending</button></li>
                <li><button onClick={() => handleSorting('rejected')} className="dropdown-item" type="button">Rejected</button></li>
              </ul>
            </div>
            <input type="text" placeholder='Search' onChange={handleFilterTable} />
          </div>
          <div className='w-100 responsiveTableParent'>
            <table className='w-100'>
              <thead className='affTableHeader'>
                <tr>
                  <th className='boder-Rght'>Wallet Address</th>
                  <th className='boder-Rght'>Username</th>
                  <th className='boder-Rght'>Status</th>
                  <th className='boder-Rght'>View</th>
                </tr>
              </thead>
              <tbody className='referralTableBody'>
                {refFilterData.length > 0 ? (
                  refFilterData.map((referral) => (
                    <tr key={referral?._id}>
                      <td className='w-50'>{referral?.walletaddress}</td>
                      <td className='text-capitalize'>{referral?.username}</td>
                      <td className='text-capitalize'>{referral?.status === 'approve' ? 'Approved' : referral?.status}</td>
                      <td><span onClick={() => handleRedirectToDetailsPage(referral?._id)}>
                        <IoEyeOutline fontSize={20} />
                      </span></td>
                    </tr>
                  ))
                ) : (
                  <tr> 
                    <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='w-100 d-flex justify-content-between'>
            <span className='fw-bold'>Showing {activePageNo} to {totalPages} of {totalDataResult} Entries</span>
            <div className='AffTxTableButton'>
              <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                <span>Pre</span>
              </button>
              {((totalPages > 0) ? [...Array(totalPages).keys()] : [...Array(1).keys()])
              .slice(Math.max(0, activePageNo - 3), Math.min(totalPages, activePageNo + 2))
              .map((_, index) => (
                  <button
                      className={`${activePageNo === index + Math.max(0, activePageNo - 3) + 1 && 'activePaginationbtn'}`}
                      key={index + Math.max(0, activePageNo - 3) + 1}
                      onClick={() => handlePageNumber(index + Math.max(0, activePageNo - 3) + 1)}
                  >
                      {index + Math.max(0, activePageNo - 3) + 1}
                  </button>
              ))}
              <button disabled={activePageNo === totalPages} onClick={() => handlePageNumber(activePageNo + 1)}>
                <span>Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



