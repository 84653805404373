import React from 'react'
import './Index.css'
import magnumLogo from '../../assets/magnumMetaLogo.png'
import AuthButton from '../Buttons/Buttons'
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { PiTelegramLogo } from "react-icons/pi";



export default function Index() {
    return (
        <>
            <div className="commingSoonCon">
                <img src={magnumLogo} alt="magnumLogo" />
                <div className="commingSoonBox">
                    <h1>Coming Soon..!</h1>
                    <p>We are working on this page, it will be live soon.</p>
                    <p>Enter your e-mail to get update first.</p>
                    <a rel="noopener noreferrer" href="https://t.me/+wNxauloybgYzNDQ8" target='_blank'>
                        <AuthButton style={{ width: '15rem', marginTop: '2rem' }}> <PiTelegramLogo fontSize={30} /> Know more! <MdOutlineArrowRightAlt fontSize={40} /> </AuthButton>
                    </a>
                </div>
            </div>
        </>
    )
}
