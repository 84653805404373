import React, { useState, useEffect } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdOutlineContentCopy } from 'react-icons/md';
import { someConciseAddress } from '../../Utils/ConciseAddress';
export default function LeadershipRankDetails() {
    const navigate = useNavigate();
    const location = useLocation()
    const [copy, setCopy] = useState(false);
    const [status, setStatus] = useState('Pending');
    const [leadership, setLeadership] = useState(location?.state)
    const [loader, setLoader] = useState(false);

    const handleCopy = async (address) => {
        if (address) {
            await navigator?.clipboard?.writeText(address);
            setCopy(true);
        }
    };
    if(loader) return <Loader />
    return (
        <>
            <div className="referralDetails_container">
                <div className="refferal_header">
                    <p>
                        <span onClick={() => navigate(-1)}>
                            <GoArrowLeft fontSize={27} />
                        </span>
                        <span className='ms-3'>Leadership Rank Detail</span>
                    </p>
                </div>
                <div className='referral_mainBox'>
                    <div className='row h-100 d-flex'>
                        <div className='col-md-6 col-sm-12 d-flex flex-column gap-3'>
                            <div className="Affi_dataBox">
                                <span>Rank : </span> 
                                <span>{leadership?.rank}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Username : </span> 
                                <span>{leadership?.username}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Wallet Address : </span> 
                                <span style={{fontWeight: '600'}}>{someConciseAddress(leadership?.userWalletAddress)}</span>
                                <span onClick={() => handleCopy(leadership?.userWalletAddress)}
                                    onMouseLeave={() => setCopy(false)} data-tooltip-id="my-tooltip-2" className='copyaddicon'>
                                    <MdOutlineContentCopy className='mx-2' fontSize={20} />
                                </span>
                                <ReactTooltip
                                    id="my-tooltip-2"
                                    place="top"
                                    variant="dark"
                                    content={copy ? 'copied' : 'copy'}
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 d-flex flex-column gap-3'>

                            <div className="Affi_dataBox">
                                <span>No of Referals : </span> 
                                <span>{leadership?.allReferral}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Total Earning : </span> 
                                <span>{leadership?.totalEarning}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Withdrawable Amount : </span> 
                                <span>{leadership?.withdrawableAmount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
