import React, { useEffect, useRef, useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import './EditArticles.css';
import { IoImagesOutline } from "react-icons/io5";
import { Editor } from 'primereact/editor';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { errorPopup, successPopup } from '../../Utils/ToasterMessage';
import AuthButton from '../../components/Buttons/Buttons';
import Loader from '../../components/Loader/Loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoaderSpinner from '../../components/Spinner/LoaderSpinner';
import { Spinner } from 'react-bootstrap';

const EditTextArticle = ({ newsId = '' }) => {
    const [blogDescription, setBlogDescription] = useState('');
    const [loader, setLoader] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const blogId = location.state.blogId;
   const [spinner,setSpinner]=useState(false)
    const fileUplodRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            newSTitle: '',
            blogDescription: '',
            imageUrl: ''
        },
        validationSchema: Yup.object({
            newSTitle: Yup.string().required('News Title is required.'),
            blogDescription: Yup.string().transform((value)=>value.replace(/\n/g, '')).required('Content is required.'),
            
        }),
        onSubmit: async (values) => {
            setSpinner(true)
            const S3ImageUrl = await uploadImage();
            const updateBody = {
                heading: values.newSTitle,
                imgurl: S3ImageUrl?.data?.data || fileName,
                description: values.blogDescription,
                type: 'blog'
            };

            await axios.patch(`${URLS.updateNews}/${blogId}`, updateBody, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
                .then((res) => {
                    successPopup(res?.data?.message);
                    // navigate('/news')
                    getNews_ById();
                    setSpinner(false)
                })
                .catch((err) => {
                    errorPopup(err?.response?.data?.message);
                });
        }
    });

    const handleEditorOnChange = (value) => {
        setBlogDescription(value.textValue)
        formik.setFieldValue('blogDescription', value.textValue);
    };

    const handlefileUpload = () => {
        fileUplodRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageUrl(file);
        setFileName(file ? file.name : '');
        formik.setFieldValue('imageUrl', file ? file.name : '');
    };

    const decodeHtmlEntities = (encodedString) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    };

    const getNews_ById = () => {
        setLoader(true);
        try {
            axios.get(`${URLS.getNewsbyId}/${blogId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
                .then((res) => {
                    setFileName(res?.data?.data?.imgurl);
                    formik.setFieldValue('blogDescription', decodeHtmlEntities(res?.data?.data?.description));
                    formik.setFieldValue('newSTitle', res?.data?.data?.heading);
                    setLoader(false);
                })
                .catch(err => {

                    setLoader(false);
                });
        } catch (error) { }
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append('imageUrl', imageUrl);
        if (imageUrl) {
            const res = await axios.post(URLS.uploadImage, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
                .then(res => {
                    return res;
                })
                .catch(err => { });
            return res;
        }
    };

    useEffect(() => {
        getNews_ById();
    }, [blogId]);

    const header = (
        <span className="ql-formats">
            <select className="ql-header">
                <option value="1">Heading</option>
                <option value="2">Subheading</option>
                <option value="3">Normal</option>
            </select>
            <select className="ql-size">
                <option value="small">Small</option>
                <option value="normal">Normal</option>
                <option value="large">Large</option>
                <option value="huge">Huge</option>
            </select>
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-link" aria-label="Link"></button>
        </span>
    );

    return (
        <>

            {loader ? (<Loader/>) :

                (

                    <div className="editTextArticle">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 onClick={() => navigate('/news')} className='editNewsHeader'>
                                <GoArrowLeft fontSize={20} />
                                <span className='editNews'>Edit News Details</span>
                            </h1>
                        </div>
                        <form onSubmit={formik.handleSubmit} className='d-flex gap-4 flex-column'>
                            <div className="newsTitle">
                                <p className='m-0 mb-2'>News Title</p>
                                <input
                                    className='newsTitleInput'
                                    value={formik.values.newSTitle}
                                    onChange={formik.handleChange}
                                    name="newSTitle"
                                    type="text"
                                />
                                {formik.errors.newSTitle && formik.touched.newSTitle && (
                                    <div className='error'>{formik.errors.newSTitle}</div>
                                )}
                            </div>
                            <div className="newsTitle">
                                <p className='m-0 mb-2'>Image for News</p>
                                <div className='uploadImageIcon'>
                                    <input
                                        className=''
                                        value={fileName}
                                        type="text"
                                        placeholder='Image Url'
                                        disabled
                                    />
                                    <input
                                        accept='.png, .svg, .jpeg'
                                        className='fileUploadMainInput'
                                        onChange={handleImageChange}
                                        ref={fileUplodRef}
                                        type="file"
                                    />
                                    <span onClick={handlefileUpload}>
                                        <IoImagesOutline fontSize={30} />
                                    </span>

                                </div>
                             
                            </div>
                            <div className='textArticleEditorBox'>
                                <h1>Content of News</h1>
                                <Editor
                                    value={formik.values.blogDescription}
                                    style={{ height: '255px', fontSize: "16px" }}
                                    onTextChange={handleEditorOnChange}
                                    headerTemplate={header}
                                />
                                {formik.errors.blogDescription && formik.touched.blogDescription && (
                                    <div className='error'>{formik.errors.blogDescription}</div>
                                )}
                            </div>
                            <div className='editBtn'>
                                <AuthButton type="submit" style={{ width: '507px', height: '41px'}}>
                                {spinner && <Spinner style={{width:'30px' , height:'30px'}} animation="border"  />} Update News   
                                </AuthButton>
                            </div>
                        </form>
                    </div>

                )


            }

        </>
    );
}

export default EditTextArticle;
