import React, { useState, useRef, useEffect } from 'react';
// import './Referral.css';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthButton, { OutlineButton } from '../../components/Buttons/Buttons';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdOutlineContentCopy } from 'react-icons/md';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import Loader from '../../components/Loader/Loader';

export default function VestingDetails() {
    const navigate = useNavigate();
    const location = useLocation()
    const [copy, setCopy] = useState(false);
    const [status, setStatus] = useState('Pending');
    const [leadership, setLeadership] = useState({})
    const [loader, setLoader] = useState(false)

    const handleCopy = async (address) => {
        if (address) {
            await navigator.clipboard.writeText(address);
            setCopy(true);
        }
    };

    const get_singleLeadership = async () => {
        let level = location.state
        setLoader(true)
        try {
            let response = await axios.get(`${URLS.getSingleLeadership}/${level}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
            setLeadership(response?.data?.data)
            setLoader(false)

        } catch (error) {
        }
    }

    const handleStatusChange = (newStatus, address) => {
        setLoader(true)
        const statusBody = {
            "refstatus": newStatus,
            "walletAdress": address,
        }
        axios.patch(`${URLS.changeRefStatus}`,
            statusBody,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
            .then((res) => {
                setStatus(newStatus);
                get_singleLeadership()
                setLoader(false)
            })
            .catch((err) => {
            });
    };
    useEffect(() => {
        get_singleLeadership()
    }, [])

    if(loader) return <Loader />;
    
    return (
        <>
            <div className="referralDetails_container">
                <div className="refferal_header">
                    <p >
                        <span onClick={() => navigate(-1)}>
                            <GoArrowLeft fontSize={27} />
                        </span>
                        <span className='ms-3'>Vesting Detail</span>
                    </p>
                </div>
                <div className='referral_mainBox leadership-hei'>
                    <div className='row h-100 d-flex'>
                        <div className='col-md-6 col-sm-12 d-flex flex-column gap-3'>
                            {/* <div className='Affi_walletAddress'>
                                <span>Wallet Address :</span>
                            </div> */}
                            {/* <div className='button-end'>
                                <OutlineButton style={{ width: '210px' }} onClick={() => handleStatusChange('reject', leadership?.walletaddress)}>Reject</OutlineButton>
                            </div> */}
                            <div className="Affi_dataBox">
                                <span>Await Period :</span> <span>{`${leadership?.awaitPeriodDays} Days`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Grace :</span> <span>{`${leadership?.graceDays} Days`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level :</span> <span>{`${leadership?.level}`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Pool Share :</span> <span>{`${leadership?.poolSharePercentage} %`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Qualify Amount :</span> <span>{`${leadership?.qualifyAmountInUSD} $`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Release Vesting :</span> <span>{`${leadership?.releaseVestingDays} Days`}</span>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 d-flex flex-column gap-3 box-2 wallet-addr'>
                            {/* <div>
                                <span className='s-1'>{leadership?.walletAdress}</span>
                                <span onClick={() => handleCopy(leadership?.walletAdress)}
                                    onMouseLeave={() => setCopy(false)} data-tooltip-id="my-tooltip-2" className='copyaddicon'>
                                    <MdOutlineContentCopy className='mx-2' fontSize={20} />
                                </span>
                                <ReactTooltip
                                    id="my-tooltip-2"
                                    place="top"
                                    variant="dark"
                                    content={copy ? 'copied' : 'copy'}
                                />
                            </div> */}
                            {/* <div className=''>
                                <AuthButton style={{ width: '210px' }} onClick={() => handleStatusChange('approve', leadership?.walletaddress)}>Approve</AuthButton>
                            </div> */}
                            <div className="Affi_dataBox">
                                <span>Level 1 Contribution :</span> <span>{`${leadership?.level1ContributionPercentage} %`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 2 Contribution :</span> <span>{`${leadership?.level2ContributionPercentage} %`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 3 Contribution :</span> <span>{`${leadership?.level3ContributionPercentage} %`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 4 Contribution :</span> <span>{`${leadership?.level4ContributionPercentage} %`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 5 Contribution :</span> <span>{`${leadership?.level5ContributionPercentage} %`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 5 Contribution :</span> <span>{`${leadership?.level5ContributionPercentage} %`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
