import React from 'react'
import './Layout.css'
import SideBar from '../../components/Navbar/Sidebar/SideBar'
import Header from '../../components/Navbar/Header/Header'
import { Outlet } from 'react-router-dom'

export default function Layout() {
    return (

        <div className="d-flex h-100 w-100">

            <SideBar />
            <div className="headerWithOutlet">
                <div className="lay_topHeader">
                    <Header />
                </div>
                <div className="adminOutlet">
                    <Outlet />
                </div>
            </div>
        </div>

    )
}
