import React, { useEffect, useState } from 'react';
import filterTableIcon from '../../assets/filterTableIcon.png';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa6';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export default function Dao() {
    const navigate = useNavigate();
    const [activePageNo, setActivePageNo] = useState(1);
    const [totalPageNo, setTotalPageNo] = useState(1);
    const [loader, setLoader] = useState(false);
    const [leadershipBoardData, setLeadershipBoardData] = useState([]);
    const [refFilterData, setRefFilterData] = useState([]);

    useEffect(() => {
        getLeadershipBoardData(1);
    }, []);

    useEffect(() => {
        setRefFilterData(leadershipBoardData);
    }, [leadershipBoardData]);

    const getLeadershipBoardData = async (pageNo) => {
        setLoader(true);
        try {
            const { data } = await axios.get(URLS.getAllLeadership, {
                headers: { Authorization: `Bearer ${localStorage.getItem('acessToken')}` },
            });
            setLeadershipBoardData(data.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };

    const handlePageNumber = (pageNo) => {
        if (pageNo >= 1 && pageNo <= totalPageNo) {
            setActivePageNo(pageNo);
            getLeadershipBoardData(pageNo);
        }
    };

    const handleFilterTable = (e) => {
        const filterText = e.target.value.toLowerCase();
        if (!filterText) {
            setRefFilterData(leadershipBoardData);
            return;
        }
        const filteredData = leadershipBoardData.filter(({ qualifyAmountInUSD, poolSharePercentage, level, level1ContributionPercentage }) =>
            [qualifyAmountInUSD, poolSharePercentage, level, level1ContributionPercentage].some(value =>
                value.toString().toLowerCase().includes(filterText)
            )
        );
        setRefFilterData(filteredData);
    };

    const handleRedirectToDetailsPage = (level) => {
        navigate('/dao-details', { state: level });
    };

    if(loader) return <Loader />;
    
    return (
        <div className="referral_container">
            <div className="referralTable">
                <h1>Dao</h1>
                <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
                    <input type="text" placeholder='Search' onChange={handleFilterTable} />
                </div>
                <div className='w-100 responsiveTableParent'>
                    <table className='w-100'>
                        <thead className='affTableHeader'>
                            <tr>
                                <th className='boder-Rght'>Data 1</th>
                                <th className='boder-Rght'>Data 2</th>
                                <th className='boder-Rght'>Data 3</th>
                                <th className='boder-Rght'>Data 4</th>
                                <th className='boder-Rght'>Data 5</th>
                            </tr>
                        </thead>
                        <tbody className='referralTableBody'>
                            {refFilterData.length > 0 ? (
                                refFilterData?.map((referral, index) => (
                                    <tr key={referral?._id}>
                                        <td>{referral?.qualifyAmountInUSD.$numberDecimal || referral?.qualifyAmountInUSD}</td>
                                        <td>{referral?.poolSharePercentage}</td>
                                        <td>{referral?.level}</td>
                                        <td>{referral?.level1ContributionPercentage}</td>
                                        <td>
                                            <span onClick={() => handleRedirectToDetailsPage(referral?.level)}>
                                                <IoEyeOutline fontSize={20} />
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='w-100 d-flex justify-content-between'>
                    <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
                    <div className='AffTxTableButton'>
                        <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                            <span>Pre</span>
                        </button>
                        {[...Array(totalPageNo)].map((_, index) => (
                            <button
                                key={index}
                                className={activePageNo === index + 1 ? 'activePaginationbtn' : ''}
                                onClick={() => handlePageNumber(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button disabled={activePageNo === totalPageNo} onClick={() => handlePageNumber(activePageNo + 1)}>
                            <span>Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}