import React from 'react';
import './ComingSoon.css';
import MagnumIcon from '../../assets/coming-soon-mangum-meta.png';
import VecotrArrow from '../../assets/vector_arrow.png';
import ArrowWhite from '../../assets/arrow_white.png';
import ArrowBlue from '../../assets/arrow_blue.png';
import { useNavigate } from 'react-router-dom';

export default function ComingSoon() {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/migration');
    }
    return (
        <section className='coming-soon-cont'>
            <div className='cmng-sn-img-cont'> 
                <img src={MagnumIcon} alt='' />
            </div>

            <div className='cmng-sn-content-cont'>
                <div className='coming-soon-txt'>
                    Coming Soon..!
                </div> 

                <div className='cmng-sn-desc'>
                    We are working on this page, it will be live soon. Enter your e-mail to get update first.
                </div>

                <div className='cmng-sn-btn-cont'>
                    <a className='cmng-sn-know-more-btn' href='https://t.me/magnummeta' target='_blank'>
                        <img src={VecotrArrow} alt='' />
                        Know More!
                        <img src={ArrowWhite} alt='' />
                    </a>

                    <div className='cmng-sn-exp-btn' onClick={handleNavigate}>
                        Explore other pages till then &nbsp;
                        <img src={ArrowBlue} alt='' />
                    </div>
                </div>
            </div>
        </section>
    )
}

