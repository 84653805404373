import React, { useContext, useState } from 'react';
import magnumMataLogo from '../../assets/magnumMetaLogo.png';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import AuthButton from '../Buttons/Buttons';
import LoginOtpModal from '../PopUpModal/LoginOtpModal';
import UserContext from '../ContextApi/UserContext';
import { URLS } from '../../Utils/app_urls';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { errorPopup, successPopup } from '../../Utils/ToasterMessage';
import { Spinner } from 'react-bootstrap';
import './Auth.css';

export default function Login() {
    const navigate = useNavigate();
    const { setLoginOtpModal } = useContext(UserContext);
    const [showPassword, setShowPassword] = useState(false);
    const [loginAuth, setLoginAuth] = useState({});
    const [loader, setLoader] = useState(false);

    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Please Enter your password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                ),
        }),
        onSubmit: async (userDetails) => {
            setLoader(true);
            try {
                const res = await axios.post(URLS.login, userDetails);
                successPopup(res?.data?.message);
                setLoginAuth(res?.data?.data);
                setLoginOtpModal(true);
            } catch (err) {
                errorPopup(err?.response?.data?.message);
            } finally {
                setLoader(false);
            }
        }
    });

    return (
        <div className='position-relative'>
            <LoginOtpModal auth={loginAuth} userDetails={formik.values} />
            <div className="login_main">
                <div className="login_container">
                    <img src={magnumMataLogo} alt="Magnum Meta Logo" loading="lazy" />
                    <div className="inputContainer mt-3">
                        <h1 className='mb-4'>Admin Dashboard</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='d-flex flex-column userInput mb-4'>
                                <label htmlFor="email">Username</label>
                                <input 
                                    id='email'
                                    name='email'
                                    type="email"
                                    placeholder='Enter Username'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={formik.errors.email && formik.touched.email ? 'input-error' : ''}
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <span className="input-feedback text-danger auth-error text-start">{formik.errors.email}</span>
                                )}
                            </div>
                            <div className='d-flex flex-column userInput'>
                                <label htmlFor="password">Password</label>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                     <input
                                        id='password'
                                        name='password'
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Enter your password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        className={formik.errors.password && formik.touched.password ? 'input-error' : ''}
                                    />
                                    <span  role="button" onClick={() => setShowPassword(prev => !prev)}>
                                        {showPassword ? <IoEyeOffOutline fontSize={'22px'} /> : <IoEyeOutline fontSize={'22px'} />}
                                    </span>
                                </div>
                                {formik.errors.password && formik.touched.password && (
                                    <span className="input-feedback text-danger auth-error text-start">{formik.errors.password}</span>
                                )}
                                <p className='m-0 text-end forgot_passtext'>
                                    <span onClick={() => navigate('/forgot-password')}>Forgot Password ?</span>
                                </p>
                            </div>
                            <AuthButton disabled={loader} type='submit' className='mt-4'>
                                {loader && <Spinner animation="border" />} Login
                            </AuthButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
