import React, { useState, useEffect } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import Loader from '../../components/Loader/Loader';

export default function DaoDetails() {
    const navigate = useNavigate();
    const { state: level } = useLocation();
    const [copy, setCopy] = useState(false);
    const [status, setStatus] = useState('Pending');
    const [leadership, setLeadership] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getSingleLeadership();
    }, []);

    const handleCopy = async (address) => {
        if (address) {
            await navigator.clipboard.writeText(address);
            setCopy(true);
        }
    };

    const getSingleLeadership = async () => {
        setLoader(true);
        try {
            const { data } = await axios.get(`${URLS.getSingleLeadership}/${level}`, {
                headers: { 
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            });
            setLeadership(data.data || {});
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };

    const handleStatusChange = async (newStatus, address) => {
        setLoader(true);
        const statusBody = {
            refstatus: newStatus,
            walletAdress: address,
        };
        try {
            await axios.patch(URLS.changeRefStatus, statusBody, {
                headers: { Authorization: `Bearer ${localStorage.getItem('acessToken')}` },
            });
            setStatus(newStatus);
            getSingleLeadership();
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };
    if(loader) return <Loader />
    return (
        <div className="referralDetails_container">
            <div className="refferal_header">
                <p>
                    <span onClick={() => navigate(-1)}>
                        <GoArrowLeft fontSize={27} />
                    </span>
                    <span className='ms-3'>Dao Detail</span>
                </p>
            </div>
            <div className='referral_mainBox leadership-hei'>
                <div className='row h-100 d-flex'>
                    <div className='col-md-6 col-sm-12 d-flex flex-column gap-3'>
                        <div className="Affi_dataBox">
                            <span>Await Period :</span> <span>{`${leadership.awaitPeriodDays} Days`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Grace :</span> <span>{`${leadership.graceDays} Days`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Level :</span> <span>{`${leadership.level}`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Pool Share :</span> <span>{`${leadership.poolSharePercentage} %`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Qualify Amount :</span> <span>{`${leadership.qualifyAmountInUSD} $`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Release Vesting :</span> <span>{`${leadership.releaseVestingDays} Days`}</span>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 d-flex flex-column gap-3 box-2 wallet-addr'>
                        <div className="Affi_dataBox">
                            <span>Level 1 Contribution :</span> <span>{`${leadership.level1ContributionPercentage} %`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Level 2 Contribution :</span> <span>{`${leadership.level2ContributionPercentage} %`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Level 3 Contribution :</span> <span>{`${leadership.level3ContributionPercentage} %`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Level 4 Contribution :</span> <span>{`${leadership.level4ContributionPercentage} %`}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Level 5 Contribution :</span> <span>{`${leadership.level5ContributionPercentage} %`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
