import React, { useEffect, useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import './EditArticles.css';
import { Editor } from 'primereact/editor';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { errorPopup, successPopup } from '../../Utils/ToasterMessage';
import AuthButton from '../../components/Buttons/Buttons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';

const EditVideoArticles = () => {
  const [blogDescription, setBlogDescription] = useState('');
  // const [newSTitle, setNewsTitle] = useState('');
  const location = useLocation();
  // const [videoUrlLink, setVideoUrlLink] = useState('');
  // const [blogDetails, setBlogDetails] = useState({});
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(false)
  const blogId = location?.state?.blogId;

  useEffect(() => {
    getNewsById();
  }, [blogId]);

  const getNewsById = () => {
    setLoader(true);
    try {
      axios.get(`${URLS.getNewsbyId}/${blogId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`
        }
      })
        .then((res) => {

          formik.setFieldValue('newSTitle', res?.data?.data?.heading)
          formik.setFieldValue('blogDescription', decodeHtmlEntities(res?.data?.data?.description))
          formik.setFieldValue('videoUrlLink', res?.data?.data?.youtubeLink)
          setLoader(false);

        })
        .catch(err => {
          setLoader(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const decodeHtmlEntities = (encodedString) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  };

  const handleEditorOnChange = (value) => {
    setBlogDescription(value.textValue);
    formik.setFieldValue('blogDescription', value.textValue);
    formik.setFieldTouched('blogDescription', true); // Manually mark field as touched
  };

  const formik = useFormik({
    initialValues: {
      newSTitle: '',
      videoUrlLink: '',
      blogDescription: '',
    },
    validationSchema: Yup.object({
      newSTitle: Yup.string().required('News Title is required.'),
      videoUrlLink: Yup.string().required('Video Link is required'),
      blogDescription: Yup.string().required('Content is required.'),
    }),
    onSubmit: async (values) => {
      setSpinner(true)
      const updateBody = {
        heading: values.newSTitle,
        description: values.blogDescription,
        type: 'youtube',
        youtubeLink: values.videoUrlLink,
      };
      try {
        const res = await axios.patch(`${URLS.updateNews}/${blogId}`, updateBody, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
          },
        });
        successPopup(res?.data?.message);
        // navigate('/news')
        getNewsById();
        setSpinner(false)
      } catch (err) {
        console.error(err);
        errorPopup(err?.response?.data?.message || 'Failed to update news');
      }
    },
  });

  const header = (
    <span className="ql-formats">
      <select className="ql-header">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option defaultValue>Normal</option>
      </select>
      <select className="ql-size">
        <option value="small">Small</option>
        <option value="normal">Normal</option>
        <option value="large">Large</option>
        <option value="huge">Huge</option>
      </select>
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-underline" aria-label="Underline"></button>
      <button className="ql-link" aria-label="Link"></button>
    </span>
  );

  return (
    <>

    {loader?(<Loader/>):(
      <div className="editTextArticle">
        <div className='d-flex align-items-center justify-content-between'>
          <h1 onClick={() => navigate('/news')} className='editNewsHeader'>
            <GoArrowLeft fontSize={20} />
            <span className=' editNews'>Edit News Details</span>
          </h1>
        </div>
        <form className='form  d-flex flex-column gap-4' onSubmit={formik.handleSubmit}>
          <div className="newsTitle">
            <p className='m-0 mb-2'>News Title</p>
            <input
              className='newsTitleInput'
              value={formik.values.newSTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="newSTitle"
              type="text"
              placeholder='Blog title'
            />
            {formik.errors.newSTitle && formik.touched.newSTitle && (
              <div className='error'>{formik.errors.newSTitle}</div>
            )}
          </div>
          <div className="newsTitle">
            <p className='m-0 mb-2'>News Video Url Link</p>
            <input
              className='newsTitleInput'
              value={formik.values.videoUrlLink}
              placeholder='https://abc/embed.com'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="videoUrlLink"
              type="text"
            />
            {formik.errors.videoUrlLink && formik.touched.videoUrlLink && (
              <div className='error'>{formik.errors.videoUrlLink}</div>
            )}
          </div>
          <div className='textArticleEditorBox'>
            <h1>Content of News</h1>
            <Editor
              value={formik.values.blogDescription}
              style={{ height: '255px', fontSize: "16px" }}
              onTextChange={handleEditorOnChange}
              headerTemplate={header}
            />
            {formik.errors.blogDescription && formik.touched.blogDescription && (
              <div className='error'>{formik.errors.blogDescription}</div>
            )}
          </div>
          <div className='editBtn'>
            <AuthButton type='submit' style={{ width: '507px', height: '41px' }}>

              {spinner && <Spinner animation="border" style={{ width: '30px', height: '30px' }} />} Update News</AuthButton>
          </div>
        </form>
      </div>

    )}
      
    </>
  );
};

export default EditVideoArticles;
