import React, { useEffect, useState } from 'react';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import conciseAddress from '../../Utils/ConciseAddress';
import styled from 'styled-components';

export default function Direct() {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [leadershipBoardData, setLeadershipBoardData] = useState([]);
  const [refFilterData, setRefFilterData] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [activePageNo, setActivePageNo] = useState(1);
  const totalPages = Math.ceil((totalResult || 0) / 10);

  useEffect(() => {
    setRefFilterData(leadershipBoardData);
  }, [leadershipBoardData]);

  const fetchLeadershipBoardData = async (activePageNo, pageSize = 10) => {
    setLoader(true);
    try {
      const { data } = await axios.get(`${URLS.getAllUserForDirectIndirect(activePageNo, pageSize)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      let array = [], datata = data?.data?.result;
      for (var key in datata) {
        let col = datata[key];
        let obj = {
          username: col?.username,
          verified: col?.verified ? "Verified" : "Not-Verified",
          walletAddress: col?.walletAddress,
          referralCount: col?.referralCount,
          _id: col?._id
        }
        array.push(obj);
      }
      setLoader(false);
      setLeadershipBoardData(array);
      setTotalResult(data?.data?.totalResult)
    } catch (error) {
      setLoader(false);
    }
  };

  const handlePageClick = (page) => {
    page = page + 1;

    fetchLeadershipBoardData(page)
    setActivePageNo(page)
  };

  const handlePrev = () => {
    if (activePageNo > 0) {
      fetchLeadershipBoardData(activePageNo - 1)
      setActivePageNo(activePageNo - 1)
    }
  }
  const handleNext = () => {
    fetchLeadershipBoardData(activePageNo + 1)
    setActivePageNo(activePageNo + 1)
  }

  useEffect(() => {
    fetchLeadershipBoardData(activePageNo);
  }, [])

  const handleRedirectToDetailsPage = (level) => {
    navigate(`/affiliate/personal-invite-details`, { state: level });
  };

  const handleFilterTable = (e) => {
    const filterText = e?.target?.value?.toLowerCase();
    if (!filterText) {
      setRefFilterData(leadershipBoardData);
    } else {
      const filteredData = leadershipBoardData?.filter(({ username, verified, walletAddress, referralCount }) =>
        username?.toString()?.toLowerCase()?.includes(filterText) ||
        verified?.toString()?.toLowerCase()?.includes(filterText) ||
        walletAddress?.toString()?.toLowerCase()?.includes(filterText) ||
        referralCount?.toString()?.toLowerCase()?.includes(filterText)
      );
      setRefFilterData(filteredData);
    }
  };

  if (loader && refFilterData.length === 0) return <Loader />;


  return (
    <div className="referral_container">
      <div className="referralTable">
        <h1>Personal Invite</h1>
        <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
          <input type="text" placeholder='Search' onChange={handleFilterTable} />
        </div>
        <div className='w-100 responsiveTableParent'>
          <table className='w-100'>
            <thead className='affTableHeader'>
              <tr>
                <th className='boder-Rght'>Username</th>
                <th className='boder-Rght'>Wallet Address</th>
                <th className='boder-Rght'>No. of Referral</th>
                <th className='boder-Rght'>Total Earn</th>
                <th className='boder-Rght'>View</th>
              </tr>
            </thead>
            <tbody className='referralTableBody'>
              {refFilterData.length > 0 ? (
                refFilterData.map((referral) => (
                  <tr key={referral?._id}>
                    <td>{referral?.username}</td>
                    <td>{conciseAddress(referral?.walletAddress)}</td>
                    <td>{referral?.referralCount}</td>
                    <td>{referral?.verified ? "Verified" : "Not Verified"}</td>
                    <td>
                      <span onClick={() => handleRedirectToDetailsPage(referral?.walletAddress)}>
                        <IoEyeOutline fontSize={20} />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='w-100 d-flex justify-content-between'>
          <span className='fw-bold'>Showing {activePageNo} to {totalPages} of {totalResult} Entries</span>
          <div className='AffTxTableButton'>
            <button onClick={handlePrev} disabled={activePageNo === 1}>
              <span>Pre</span>
            </button>
            {((totalPages > 0) ? [...Array(totalPages).keys()] : [...Array(1).keys()])
              .slice(Math.max(0, activePageNo - 3), Math.min(totalPages, activePageNo + 2))
              .map((_, index) => (
                  <button
                      className={`${activePageNo === index + Math.max(0, activePageNo - 3) + 1 && 'activePaginationbtn'}`}
                      key={index + Math.max(0, activePageNo - 3) + 1}
                      onClick={() => handlePageClick(index + Math.max(0, activePageNo - 3) + 1)}
                  >
                      {index + Math.max(0, activePageNo - 3) + 1}
                  </button>
              ))}
            <button disabled={activePageNo === totalPages} onClick={handleNext}>
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}