import React from 'react'
import Index from '../../components/CommingSoon/Index'

export default function Home() {
  return (
    <div>
      <Index/>
    </div>
  )
}
