const getMontsFromDurationIndex = (period) => {
    switch (period) {
      case '0':
        return '3 Months'
      case '1':
        return '5 Months'
      case '2':
        return '8 Months'
      case '3':
        return '12 Months'
      case '4':
        return '24 Months'
      default:
        break;
    }
  }

  module.exports ={
    getMontsFromDurationIndex
  }