import React, { useEffect, useState } from 'react'
import { GoArrowLeft } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import AuthButton, { OutlineButton } from '../../components/Buttons/Buttons'
import { IoCopyOutline } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


export default function VideoNews() {
    const navigate = useNavigate()
    const [copy, setCopy] = useState(false)
    const [videoNewsUrl, setVideoNewsUrl] = useState('')

    const handleCopy = async (newUrl) => {
        try {
            await navigator.clipboard.writeText(newUrl);
        } catch (err) {
        }
        setCopy(true)
    }

    useEffect(() => {
        setVideoNewsUrl('https://youtu.be/VWB8RM9qHPg?si=2hmuGjffVa9D6s9k');
    }, [])

    return (
        <>
            <div className="videoNews_container">
                <div className="d-flex justify-content-between flex-wrap align-items-end">
                    <div onClick={() => navigate('/news')} className="newDetailsTitile">
                        <GoArrowLeft fontSize={30} />
                        <span>Video News</span>
                    </div>
                    <div className='d-flex gap-4 flex-wrap'>
                        <OutlineButton style={{ width: '206px' }}>Edit News</OutlineButton>
                        <AuthButton style={{ width: '206px' }}>Delete News</AuthButton>
                    </div>
                </div>
                <div className='video_newsSection mt-2'>
                    <iframe width="100%" height="100%" src="https://youtube.com/embed/FUiu-cdu6mA?si=XfHFux6N0Bl9bhmG" frameborder="0" allowfullscreen />
                </div>
                <p className='m-0 news_updateTime '>Uploaded On: 24/11/2023</p>
                <div className='videoNewUrl'>
                    <span>Video Link :</span>
                    <a target='_blank' href="https://youtu.be/VWB8RM9qHPg?si=2hmuGjffVa9D6s9k">https://youtu.be/VWB8RM9qHPg?si=2hmuGjffVa9D6s9k</a>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{copy ? 'copied' : 'copy'}</Tooltip>}>
                        <span onClick={() => handleCopy(videoNewsUrl)} onMouseLeave={() => setCopy(false)} className="d-inline-block mx-2">
                            <span> <IoCopyOutline color='#1D70D2' fontSize={30} /> </span>
                        </span>
                    </OverlayTrigger>
                </div>
                <div className="newsdescription">
                    <p>
                        Blockchain technology is revolutionizing the way we|think about data and transactions. It offers a decentralized and secure method for recording and verifying information. This blog covers the basics of how blockchain works and its potential applications.
                    </p>
                    <p>
                        A blockchain consists of blocks, each containing a list of transactions. These blocks are linked together in a chronological order, forming a chain. Each block includes a cryptographic hash of the previous block, a timestamp, and transaction data.
                    </p>
                    <p>
                        A blockchain consists of blocks, each containing a list of transactions. These blocks are linked together in a chronological order, forming a chain.
                        Each block includes a cryptographic hash of the previous block, a timestamp, and transaction data.
                    </p>
                </div>
            </div>
        </>
    )
}
