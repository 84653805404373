import React, { useState, useEffect } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import Loader from '../../components/Loader/Loader';
import { PiPencilSimpleLineBold } from "react-icons/pi";
import styled from 'styled-components';

const Input = styled.input`
border: 0px;
padding: 1px 8px;
outline: 0px;
`
const SubmitButton = styled.button`
color: #ffffff;
font-size: 20px;
font-weight: 700;
width: 50%;
height: 45px;
font-size: 16px;
display: flex;
gap: 1.2rem;
align-items: center;
justify-content: center;
box-shadow: 0px 4px 4px 0px #00000040;
border-radius: 38px;
border: 3px solid transparent;
background: linear-gradient(90deg, #234b9a, #234b9a, #234b9a) padding-box, linear-gradient(180deg, #234b9a 0%, #ffffff 100%) border-box;
`

export default function LeadershipBoardDetails() {
    const navigate = useNavigate();
    const location = useLocation()
    const [copy, setCopy] = useState(false);
    const [status, setStatus] = useState('Pending');
    const [leadership, setLeadership] = useState({})
    const [loader, setLoader] = useState(false)
    const[isEdit, setIsEdit]=useState(false)

    const[form, setForm]= useState({
        awaitPeriod: '',
        gracePeriod: '',
        poolShare: '',
        qualifyAmount: '',
        releaseVesting: '',
        level1: '',
        level2: '',
        level3: '',
        level4: '',
        level5: ''
    })

    const handleChangeValue = (e)=>{
        const {name, value} = e.target;
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleCopy = async (address) => {
        if (address) {
            await navigator.clipboard.writeText(address);
            setCopy(true);
        }
    };

    const get_singleLeadership = async () => {
        let level = location.state
        setLoader(true)
        try {
            let response = await axios.get(`${URLS.getSingleLeadership}/${level}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
            setLeadership(response?.data?.data)
            setLoader(false)

        } catch (error) {
        }
    }
    const handleClickEdit=()=>{
        setIsEdit(true);
        setForm({
            awaitPeriod: leadership?.awaitPeriodDays,
            gracePeriod: leadership?.graceDays,
            poolShare: leadership?.poolSharePercentage,
            qualifyAmount: leadership?.qualifyAmountInUSD,
            releaseVesting: leadership?.releaseVestingDays,
            level1: leadership?.level1ContributionPercentage,
            level2: leadership?.level2ContributionPercentage,
            level3: leadership?.level3ContributionPercentage,
            level4: leadership?.level4ContributionPercentage,
            level5: leadership?.level5ContributionPercentage
        })
    }

    const handleSubmitEditedData=()=>{
        let level = location.state
        axios.patch(`${URLS.getSingleLeadership}/${level}`,{
            qualifyAmountInUSD: form.qualifyAmount,
            poolSharePercentage: form.poolShare,
            level1ContributionPercentage: form.level1,
            level2ContributionPercentage: form.level2,
            level3ContributionPercentage: form.level3,
            level4ContributionPercentage: form.level4,
            level5ContributionPercentage: form.level5,
            awaitPeriodDays: form.awaitPeriod,
            releaseVestingDays: form.releaseVesting,
            graceDays: form.gracePeriod,
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
                accept: 'application/json'
            }
        })
        .then((res)=>{
            get_singleLeadership();
            setIsEdit(false);
        })
        .catch((err)=>{
        })
    }
    // const handleStatusChange = (newStatus, address) => {
    //     setLoader(true)
    //     const statusBody = {
    //         "refstatus": newStatus,
    //         "walletAdress": address,
    //     }

    //     axios.patch(`${URLS.changeRefStatus}`,
    //         statusBody,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem('acessToken')}`
    //             }
    //         })
    //         .then((res) => {
    //             setStatus(newStatus);
    //             get_singleLeadership()
    //             setLoader(false)
    //         })
    //         .catch((err) => {
    //         });
    // };

    useEffect(() => {
        get_singleLeadership()
    }, [])

    if(loader) return <Loader />;
    
    return (
        <>
            <div className="referralDetails_container">
                <div className="refferal_header">
                    <p>
                        <span onClick={() => navigate(-1)}>
                            <GoArrowLeft fontSize={27} />
                        </span>
                        <span className='ms-3'>Leadership Board Detail</span>
                        { !isEdit && <span className='ms-1' onClick={()=>handleClickEdit()}><PiPencilSimpleLineBold  /></span>}
                    </p>
                </div>
                <div className='referral_mainBox'>
                    <div className='row h-100 d-flex'>
                        <div className='col-md-6 col-sm-12 d-flex flex-column gap-3'>
                            {/* <div className='Affi_walletAddress'>
                                <span>Wallet Address: </span>
                            </div> */}
                            {/* <div className='button-end'>
                                <OutlineButton style={{ width: '210px' }} onClick={() => handleStatusChange('reject', leadership?.walletaddress)}>Reject</OutlineButton>
                            </div> */}
                            <div className="Affi_dataBox">
                                <span>Await Period: </span> 
                                {!isEdit ? <span>{`${leadership?.awaitPeriodDays} Days`}</span>: 
                                <Input
                                    name='awaitPeriod'
                                    value={form.awaitPeriod}
                                    onChange={handleChangeValue}
                                    id='awaitPeriod'
                                />}
                            </div>
                            <div className="Affi_dataBox">
                                <span>Grace: </span> 
                                {!isEdit ? <span>{`${leadership?.graceDays} Days`}</span>:  
                                    <Input
                                        name='gracePeriod'
                                        value={form.gracePeriod}
                                        onChange={handleChangeValue}
                                        id='gracePeriod'
                                    />
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>LeadershipBoard: </span> 
                                <span>{`${leadership?.level}`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Pool Share: </span> 
                                {!isEdit ? <span>{`${leadership?.poolSharePercentage} %`}</span>:  
                                    <Input
                                        name='poolShare'
                                        value={form.poolShare}
                                        onChange={handleChangeValue}
                                        id='poolShare'                                  
                                    />
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>Qualify Amount: </span> 
                                {!isEdit ? <span>{" "}$ {`${leadership?.qualifyAmountInUSD}`}</span>:  
                                    <Input
                                        name='qualifyAmount'
                                        value={form.qualifyAmount}
                                        onChange={handleChangeValue}
                                        id='qualifyAmount'                              
                                    />
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>Release Vesting: </span> 
                                {!isEdit ? <span>{`${leadership?.releaseVestingDays} Days`}</span>:  
                                    <Input
                                        name='releaseVesting'
                                        value={form.releaseVesting}
                                        onChange={handleChangeValue}
                                        id='releaseVesting'  
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 d-flex flex-column gap-3 box-2 wallet-addr'>
                            {/* <div>
                                <span className='s-1'>{leadership?.walletAdress}</span>
                                <span onClick={() => handleCopy(leadership?.walletAdress)}
                                    onMouseLeave={() => setCopy(false)} data-tooltip-id="my-tooltip-2" className='copyaddicon'>
                                    <MdOutlineContentCopy className='mx-2' fontSize={20} />
                                </span>
                                <ReactTooltip
                                    id="my-tooltip-2"
                                    place="top"
                                    variant="dark"
                                    content={copy ? 'copied':  'copy'}
                                />
                            </div> */}
                            {/* <div className=''>
                                <AuthButton style={{ width: '210px' }} onClick={() => handleStatusChange('approve', leadership?.walletaddress)}>Approve</AuthButton>
                            </div> */}
                            <div className="Affi_dataBox">
                                <span>Level 1 Contribution: </span> 
                                { !isEdit ? <span>{`${leadership?.level1ContributionPercentage} %`}</span>:  
                                    <Input
                                        name='level1'
                                        value={form.level1}
                                        onChange={handleChangeValue}
                                        id='level1'  
                                    />  
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 2 Contribution: </span> 
                                { !isEdit ? <span>{`${leadership?.level2ContributionPercentage} %`}</span>:  
                                    <Input
                                        name='level2'
                                        value={form.level2}
                                        onChange={handleChangeValue}
                                        id='level2'  
                                    />  
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 3 Contribution: </span> 
                                { !isEdit ? <span>{`${leadership?.level3ContributionPercentage} %`}</span>:  
                                    <Input
                                        name='level3'
                                        value={form.level3}
                                        onChange={handleChangeValue}
                                        id='level3'  
                                    />  
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 4 Contribution: </span> 
                                { !isEdit ? <span>{`${leadership?.level4ContributionPercentage} %`}</span>:  
                                    <Input
                                        name='level4'
                                        value={form.level4}
                                        onChange={handleChangeValue}
                                        id='level4'  
                                    />  
                                }
                            </div>
                            <div className="Affi_dataBox">
                                <span>Level 5 Contribution: </span> 
                                { !isEdit ? <span>{`${leadership?.level5ContributionPercentage} %`}</span>:  
                                    <Input
                                        name='level5'
                                        value={form.level5}
                                        onChange={handleChangeValue}
                                        id='level5'  
                                    />  
                                }
                            </div>
                            { isEdit && <div className='text-center'>
                                <SubmitButton onClick={()=>handleSubmitEditedData()}>Submit</SubmitButton>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
