// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot_main{
    width: 100%;
    height: 100vh;
    
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/ForgotPass.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;;AAEjB","sourcesContent":[".forgot_main{\n    width: 100%;\n    height: 100vh;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
