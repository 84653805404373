import axios from "axios";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { URLS } from "../../Utils/app_urls";
export default function ReferalRewardDetails(){
    const location = useLocation();
    
    const getRewardData=()=>{
        axios.get(URLS.getRewardTransaction(location?.state?.id),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
        })
        .catch((err)=>{
        })
    }
    useEffect(()=>{
        getRewardData()
    },[location.state])
    return(
        <>
            <p>this is details section</p>
        </>
    );
}