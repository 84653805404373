import React, { useEffect, useState } from 'react'
import AuthButton, { OutlineButton } from '../../components/Buttons/Buttons'
import newsImageThumb from '../../assets/NewsIcon/newsThumbPic.png'
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import './News.css'
import Loader from '../../components/Loader/Loader';
import { IoCopyOutline } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import crossIcon from '../../assets/crossIcon.png'
import { successPopup } from '../../Utils/ToasterMessage';


export default function NewsDetails() {
    const navigate = useNavigate()
    const location = useLocation()
    const blogId = location?.state?.id
    const [blogDetails, setBlogsDetails] = useState({})
    const [loader, setLoader] = useState(false)
    const [copy, setCopy] = useState(false)
    const [deleteStatus, setDeleteStatus] = useState(false)


    const deleteNews = () => {


        axios.delete(`${URLS.deleteNews}/${blogId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`
            }
        })
            .then(res => {
                successPopup("News Deleted")
                navigate('/news')
            })
            .catch(err => {
            })



        setDeleteStatus(false)
    }

    const getNewsById = () => {
        setLoader(true)
        try {
            axios.get(`${URLS.getNewsbyId}/${blogId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
                .then(res => {
                    setBlogsDetails(res?.data?.data)
                    setLoader(false)
                })
                .catch(err => {
                    setLoader(false)
                   
                })
        } catch (error) {
        }
    }
    const decodeHtmlEntities = (encodedString) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    };

    const handleCopy = async (newUrl) => {
        try {
            await navigator.clipboard.writeText(newUrl);
        } catch (err) {
        }
        setCopy(true)
    }

    const getVideoId = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match && match[1];
    };

    const handleNavigate = (id) => {
        if (blogDetails?.imgurl) {
            navigate(`/news/edit-news-details`, {
                state: { blogId: id }
            })
        } else if (blogDetails?.youtubeLink) {
            navigate(`/news/edit-youtube-news-details`, {
                state: { blogId: id }
            })
        } else {
            navigate(`/news`)
        }
    }

    useEffect(() => {
        getNewsById();
    }, [blogId])


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();


        return `${day}/${month}/${year}`;
    };
    if(loader) return <Loader />

    return (
        <>
            <div className="news_details_container">
                {/* <button onClick={getNewsById}>getNewsById</button> */}
                <div className='d-flex justify-content-between align-items-end header_news'>
                    <div onClick={() => navigate('/news')} className="newDetailsTitile">
                        <GoArrowLeft fontSize={30} />
                        <span>News Details</span>
                    </div>
                    <div className='d-flex gap-4 flex-wrap'>
                        <OutlineButton onClick={() => handleNavigate(blogId)} style={{ width: '164px', height: '38px' }}>Edit News</OutlineButton>
                        <AuthButton onClick={() => setDeleteStatus(true)} style={{ width: '164px', height: '38px' }}>Delete News</AuthButton>
                    </div>
                </div>
                {
                    blogDetails?.description && <div className="news_details_section mt-4   ">
                        <h1>{blogDetails?.heading}</h1>
                        {blogDetails?.imgurl && <>

                            <img src={blogDetails?.imgurl} alt="newsImageThumb" />
                            <p className='newsUpdateTime'>Posted On : {formatDate(blogDetails?.updatedAt?.split('T')[0])} </p>
                        </>}
                        {blogDetails?.youtubeLink && <>
                            <div className='video_newsSection mt-2'>
                                <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${getVideoId(blogDetails?.youtubeLink)}`} frameborder="0" allowfullscreen />
                            </div>
                            <p className='newsUpdateTime'>Posted On : {formatDate(blogDetails?.updatedAt?.split('T')[0])} </p>
                            <div className='videoNewUrl'>
                                <span>Video Link :</span>
                                <a target='_blank' href={blogDetails?.youtubeLink}>{blogDetails?.youtubeLink}</a>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{copy ? 'copied' : 'copy'}</Tooltip>}>
                                    <span onClick={() => handleCopy(blogDetails?.youtubeLink)} onMouseLeave={() => setCopy(false)} className="d-inline-block mx-2">
                                        <span> <IoCopyOutline color='#1D70D2' fontSize={28} /> </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </>}

                        {/* <p className='newsUpdateTime'>Posted On : {blogDetails?.updatedAt?.split('T')[0]} </p> */}
                        <div className="newsArticle newsdescription mb-5">
                            <div className='blogsFonts' dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(blogDetails?.description) }} />
                        </div>
                    </div>
                }
            </div>

            <Modal
                show={deleteStatus}
                onHide={() => setDeleteStatus(false)}
                backdrop="static"
                keyboard={false}
                centered
                className="custom-modal"

            >
                <Modal.Header className="modal-header-custom">
                    <Modal.Title>Delete News ?</Modal.Title>
                    <div className='close_button'>
                        <button type="button" className="custom_btn" onClick={() => setDeleteStatus(false)}>
                            <img src={crossIcon} alt="Close" className="custom-close-icon" />
                        </button>
                    </div>

                </Modal.Header>
                <Modal.Body className="text-center">Are you sure, you want to delete this news?</Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <AuthButton onClick={() => setDeleteStatus(false)} className="custom-button cancel-button">
                        Cancel
                    </AuthButton>
                    <AuthButton onClick={deleteNews} className="custom-button logout-button">
                        Delete
                    </AuthButton>
                </Modal.Footer>
            </Modal>


        </>


    )
}
