import React from 'react';
import { Oval } from 'react-loader-spinner';
import './LoaderSpinner.css'
const LoaderSpinner = () => (
  <div className="loader-container">
    <Oval
      height={50}
      width={50}
      color=" #3498db"
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#0000001a"
      strokeWidth={3}
      strokeWidthSecondary={4}
    />
  </div>
);

export default LoaderSpinner