// import React, { useContext, useEffect, useState } from 'react'
// import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
// import filterTableIcon from '../../assets/filterTableIcon.png'
// import { Tab, Table } from 'react-bootstrap'
// import axios from 'axios'
// import { URLS } from '../../Utils/app_urls'
// import { IoEyeOutline } from "react-icons/io5";
// import './TxTable.css'
// import conciseAddress from '../../Utils/ConciseAddress';
// import MigrationTxHistoryModal from '../../components/PopUpModal/MigrationTxHistoryModal';
// import UserContext from '../../components/ContextApi/UserContext';



// export default function TransactionTable() {
//     const { openMigTxModal, setOpenMigTxModal } = useContext(UserContext)
//     const [activePageNo, setActivePageNo] = useState(1)
//     const [tablePageBtn, setTablePageBtn] = useState([1, 2, 3, 4, 5]);
//     const [totalPageNo, setTotalPageNo] = useState(1)
//     const [txHistory, setTxHistory] = useState([])
//     const [toggleFilter, setToggleFIlter] = useState(false)
//     const [TxFilterData, setTxFilterData] = useState([])
//     const [showTxDetailsInModal, setshowTxDetailsInModal] = useState({})
//     const [loader, setLoader] = useState(false)

//     const handlePageNumber = (pageNo) => {
//         setActivePageNo(pageNo)
//         getTnxHistory(pageNo)
//     }

//     const handleDecPageNo = () => {
//         getTnxHistory(activePageNo - 1)
//         setActivePageNo(activePageNo - 1)
//         setTablePageBtn(tablePageBtn.map((item) => item - 1))
//     }
//     const hanleIncPageNo = () => {
//         setTablePageBtn(tablePageBtn.map((item) => item + 1))
//         getTnxHistory(activePageNo + 1)
//         setActivePageNo(activePageNo + 1)
//     }

//     const getTnxHistory = (pageNo) => {
//         setLoader(true)
//         try {
//             axios.get(`${URLS.getAllMigration}`,
//                 {
//                     params: {
//                         pageSize: '10',
//                         page: pageNo
//                     },
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('acessToken')}`
//                     }
//                 })
//                 .then((res) => {
//                     setTotalPageNo(res?.data?.data?.totalPages)
//                     setTxHistory(res?.data?.data?.data)
//                     setLoader(false)
//                 })
//                 .catch((err) => {
//                     setLoader(false)
//                 })
//         } catch (error) {
//         }

//     }


//     const handleSorting = (order) => {
//         //sorting Tx history using data
//         if (order === 'asc') {
//             const AscFilterData = txHistory.sort((a, b) => {
//                 return (b?.updatedAt).localeCompare(a?.updatedAt)
//             })
//             setTxFilterData(AscFilterData)
//         } else if (order === 'dsc') {
//             const AscFilterData = txHistory.sort((a, b) => {
//                 return (a?.updatedAt).localeCompare(b?.updatedAt)
//             })
//             setTxFilterData(AscFilterData)
//         }
//         setToggleFIlter(prev => !prev)
//     }

//     const handleFilterTable = (e) => {
//         let filterText = e.target.value.toLowerCase();
//         const filteredData = txHistory.filter(item => {
//             let { transfers, ...rest } = item;
//             let match1 = false;
//             let match2 = false;

//             // Check if any value in 'transfers' matches the filter text
//             Object.values(transfers).forEach((val) => {
//                 const stringValue = String(val).toLowerCase();
//                 if (stringValue.includes(filterText)) {
//                     match1 = true;
//                 }
//             });

//             // Check if any value in 'rest' matches the filter text
//             Object.values(rest).forEach((val) => {
//                 const stringValue = String(val).toLowerCase();
//                 if (stringValue.includes(filterText)) {
//                     match2 = true;
//                 }
//             });

//             return match1 || match2;
//         });

//         setTxFilterData(filteredData)
//     }

//     useEffect(() => {
//         getTnxHistory(1);
//     }, [])

//     const handleShowTxDetails = (rowData) => {
//         setshowTxDetailsInModal(rowData)
//         setOpenMigTxModal(true)
//     }

//     useEffect(() => {
//         setTxFilterData(txHistory)
//     }, [txHistory])

//     return (
//         <>
//             <MigrationTxHistoryModal rowData={showTxDetailsInModal} />
//             <div className={`tnxTable_main d-flex flex-column gap-3 ${loader && 'minimizeOpacity'}`}>
//                 <h1 className='m-0'>Transaction History</h1>
//                 <div className="tableFilterSection d-flex justify-content-between align-items-end flex-wrap gap-2">
//                     <input type="text"
//                         placeholder='Search'
//                         onChange={handleFilterTable}
//                     />
//                     <div className="sorttablebtn dropdown drop-down-box">
//                         <button type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="true" className="filter_btn w-100 h-100">
//                             <img src={filterTableIcon} alt="filterTable Icon" />
//                             Filter
//                         </button>
//                         <ul className="dropdown-menu filter_drop_box" aria-labelledby="dropdownMenu2">
//                             <li><button onClick={() => handleSorting('dsc')} className="dropdown-item" type="button">Asc order</button></li>
//                             <li><button onClick={() => handleSorting('asc')} className="dropdown-item" type="button">Dsc order</button></li>
//                         </ul>
//                     </div>
//                 </div>
//                 <div className="tableContainer">

//                     <div className='widthRespos'>
//                         <Table className='w-100' hover>
//                             <thead className='tnxHistoryheader'>
//                                 <tr>
//                                     <th><span className='hdrbdrlft'>TX ID</span></th>
//                                     <th><span className='hdrbdrlft'>Date</span></th>
//                                     <th><span className='hdrbdrlft'>Polygon Address</span></th>
//                                     <th><span className='hdrbdrlft'>Total MGB Token</span></th>
//                                     <th><span className='hdrbdrlft'>Burn MGB Token</span></th>
//                                     <th><span className='hdrbdrlft'>TX Hash</span></th>
//                                     <th><span className='hdrbdrlft'>Binance Wallet Add</span></th>
//                                     <th><span className='hdrbdrlft'>MFORGE</span></th>
//                                     <th><span>View</span></th>
//                                 </tr>
//                             </thead>
//                             <tbody className='tnxhtrbdytbl'>
//                                 {
//                                     TxFilterData.map((items, index) => (
//                                         <tr key={index}>
//                                             <td>{items?.hash.slice(-6)}</td>
//                                             <td style={{ width: '130px' }}>{items?.updatedAt?.split('T')[0]}</td>
//                                             <td>{conciseAddress(items?.from)}</td>
//                                             <td>{Number(items?.totalbalance || 0).toFixed(4)}</td>
//                                             <td>{Number(items?.balance || 0).toFixed(4)}</td>
//                                             <td>{conciseAddress(items?.transfers?.hash)}</td>
//                                             <td>{conciseAddress(items?.to)}</td>
//                                             <td>{Number(items?.transfers?.balance || 0).toFixed(2)}</td>
//                                             <td><span onClick={() => handleShowTxDetails(items)}><IoEyeOutline fontSize={20} /></span></td>
//                                         </tr>
//                                     ))
//                                 }
//                             </tbody>
//                         </Table>
//                     </div>
//                     <div className="TxtableFooter">
//                         <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
//                         <div className='TxTableButton'>
//                             <button disabled={tablePageBtn[0] === 1} onClick={() => handleDecPageNo()}>
//                                 {/* <FaCaretLeft fontSize={18} /> */}
//                                 <span>Pre</span>
//                                 </button>
//                             {
//                                 tablePageBtn.map((items, index) => (
//                                     <button className={`${activePageNo === items && 'activePaginationbtn'}`} disabled={tablePageBtn[index] >= totalPageNo} onClick={() => handlePageNumber(items)} key={index}> {items} </button>
//                                 ))
//                             }
//                             <button disabled={tablePageBtn[4] >= totalPageNo} onClick={() => hanleIncPageNo()}> 
//                                 {/* <FaCaretRight fontSize={18} />  */}
//                                 <span>Next</span>
//                                 </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }


import React, { useContext, useEffect, useState } from 'react';
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import filterTableIcon from '../../assets/filterTableIcon.png';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { IoEyeOutline } from "react-icons/io5";
import './TxTable.css';
import conciseAddress from '../../Utils/ConciseAddress';
import MigrationTxHistoryModal from '../../components/PopUpModal/MigrationTxHistoryModal';
import UserContext from '../../components/ContextApi/UserContext';

export default function TransactionTable() {
    const { openMigTxModal, setOpenMigTxModal } = useContext(UserContext);
    const [activePageNo, setActivePageNo] = useState(1);
    const [totalPageNo, setTotalPageNo] = useState(1);
    const [totalDataResult, setTotalDataResult] = useState(0);
    const [txHistory, setTxHistory] = useState([]);
    const [txFilterData, setTxFilterData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showTxDetailsInModal, setShowTxDetailsInModal] = useState({});

    useEffect(() => {
        getTnxHistory(1);
    }, []);

    useEffect(() => {
        setTxFilterData(txHistory);
    }, [txHistory]);

    const getTnxHistory = async (pageNo) => {
        setLoader(true);
        try {
            const { data } = await axios.get(URLS.getAllMigration, {
                params: { pageSize: '10', page: pageNo },
                headers: { Authorization: `Bearer ${localStorage.getItem('acessToken')}` },
            });
            setTotalDataResult(data.data.totalDocuments)
            setTotalPageNo(data.data.totalPages);
            setTxHistory(data.data.data);
        } catch (error) {
            console.error('Error', error);
        } finally {
            setLoader(false);
        }
    };

    const handlePageNumber = (pageNo) => {
        setActivePageNo(pageNo);
        getTnxHistory(pageNo);
    };

    const handleDecPageNo = () => handlePageNumber(activePageNo - 1);
    const handleIncPageNo = () => handlePageNumber(activePageNo + 1);

    const handleSorting = (order) => {
        const sortedData = [...txHistory].sort((a, b) =>
            order === 'asc'
                ? a.updatedAt.localeCompare(b.updatedAt)
                : b.updatedAt.localeCompare(a.updatedAt)
        );
        setTxFilterData(sortedData);
    };

    const handleFilterTable = (e) => {
        const filterText = e.target.value.toLowerCase();
        const filteredData = txHistory.filter(({ transfers, ...rest }) => {
            const values = [...Object.values(transfers), ...Object.values(rest)];
            return values.some(val => String(val).toLowerCase().includes(filterText));
        });
        setTxFilterData(filteredData);
    };

    const handleShowTxDetails = (rowData) => {
        setShowTxDetailsInModal(rowData);
        setOpenMigTxModal(true);
    };

    return (
        <>
            <MigrationTxHistoryModal rowData={showTxDetailsInModal} />
            <div className={`tnxTable_main d-flex flex-column gap-3 ${loader && 'minimizeOpacity'}`}>
                <h1 className='m-0'>Transaction History</h1>
                <div className="tableFilterSection mb-3 mb-md-0 d-flex justify-content-between align-items-end">
                    <div className="sorttablebtn dropdown drop-down-box">
                        <button type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="true" className="filter_btn">
                            <img src={filterTableIcon} alt="filterTable Icon" />
                            Filter
                        </button>
                        <ul className="dropdown-menu filter_drop_box" aria-labelledby="dropdownMenu2">
                            <li><button onClick={() => handleSorting('asc')} className="dropdown-item" type="button">Asc order</button></li>
                            <li><button onClick={() => handleSorting('dsc')} className="dropdown-item" type="button">Dsc order</button></li>
                        </ul>
                    </div>
                    <input type="text" placeholder='Search' onChange={handleFilterTable} />

                </div>
                <div className="tableContainer">
                    <div className='widthRespos'>
                        <Table className='w-100' hover>
                            <thead className='tnxHistoryheader'>
                                <tr>
                                    <th className='boder-Rght'>TX ID</th>
                                    <th className='boder-Rght'>Date</th>
                                    <th className='boder-Rght'>Polygon Address</th>
                                    <th className='boder-Rght'>Total MGB Token</th>
                                    <th className='boder-Rght'>Burn MGB Token</th>
                                    <th className='boder-Rght'>TX Hash</th>
                                    <th className='boder-Rght'>Binance Wallet Add</th>
                                    <th className='boder-Rght'>MFORGE</th>
                                    <th className='boder-Rght'>View</th>
                                </tr>
                            </thead>
                            <tbody className='tnxhtrbdytbl'>
                                {txFilterData.length > 0 ? (
                                    txFilterData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.hash.slice(-6)}</td>
                                            <td style={{ width: '130px' }}>{item.updatedAt.split('T')[0]}</td>
                                            <td>{conciseAddress(item.from)}</td>
                                            <td>{Number(item.totalbalance || 0).toFixed(4)}</td>
                                            <td>{Number(item.balance || 0).toFixed(4)}</td>
                                            <td>{conciseAddress(item.transfers.hash)}</td>
                                            <td>{conciseAddress(item.to)}</td>
                                            <td>{Number(item.transfers.balance || 0).toFixed(2)}</td>
                                            <td><span onClick={() => handleShowTxDetails(item)}><IoEyeOutline fontSize={20} /></span></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9" className='data_not fw-bold'>No Data Available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div className="TxtableFooter">
                        <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalDataResult} Entries</span>
                        <div className='TxTableButton'>
                            <button disabled={activePageNo === 1} onClick={handleDecPageNo}>Pre</button>
                            {((totalPageNo > 0) ? [...Array(totalPageNo).keys()] : [...Array(1).keys()])
                            // .slice(activePageNo -1, activePageNo +4)
                            // .map((_, index) => (
                            //     <button
                            //         className={`${activePageNo === index + activePageNo && 'activePaginationbtn'}`}
                            //         key={index + activePageNo}
                            //         onClick={() => handlePageNumber(index + activePageNo)}
                            //     >
                            //         {index + activePageNo}
                            //     </button>
                            // ))}
                            .slice(Math.max(0, activePageNo - 3), Math.min(totalPageNo, activePageNo + 2))
                            .map((_, index) => (
                                <button
                                    className={`${activePageNo === index + Math.max(0, activePageNo - 3) + 1 && 'activePaginationbtn'}`}
                                    key={index + Math.max(0, activePageNo - 3) + 1}
                                    onClick={() => handlePageNumber(index + Math.max(0, activePageNo - 3) + 1)}
                                >
                                    {index + Math.max(0, activePageNo - 3) + 1}
                                </button>
                            ))}
                            <button disabled={activePageNo === totalPageNo} onClick={() => handlePageNumber(activePageNo + 1)}>
                                <span>Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
