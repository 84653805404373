import styled from "styled-components";

const AuthButton = styled.button`
color: #ffffff;
font-size: 20px;
font-weight: 700;
width: 100%;
height: 45px;
font-size: 16px;
display: flex;
gap: 1.2rem;
align-items: center;
justify-content: center;
box-shadow: 0px 4px 4px 0px #00000040;
border-radius: 38px;
border: 3px solid transparent;
background: linear-gradient(90deg, #234b9a, #234b9a, #234b9a) padding-box,
  linear-gradient(180deg, #234b9a 0%, #ffffff 100%) border-box;

  &:hover {
    color: #000;
    border: 3px solid transparent;
    background: linear-gradient(90deg, #234b9a20, #234b9a50, #234b9a60) padding-box,
    linear-gradient(180deg, #234b9a 0%, #ffffff40 100%) border-box;
    transition: 0.3s ease-in-out;
    cursor:pointer;
  }
  &:disabled{
    opacity: 0.6;
  }
`

export const OutlineButton=styled.button`
color: #234B9A;
font-size: 20px;
font-weight: 700;
width: 100%;
height: 45px;
font-size: 16px;
display: flex;
gap: 1.2rem;
align-items: center;
justify-content: center;
box-shadow: 0px 4px 4px 0px #00000040;
border-radius: 38px;
border: 3px solid transparent;
background: linear-gradient(90deg, #ffffff, #ffffff, #ffffff) padding-box,
linear-gradient(180deg, #234b9a 0%, #ffffff 100%) border-box;

&:hover {
  color: #000;
  border: 3px solid transparent;
  background: linear-gradient(90deg, #234b9a20, #234b9a50, #234b9a60) padding-box,
  linear-gradient(180deg, #234b9a 0%, #ffffff40 100%) border-box;
  transition: 0.3s ease-in-out;
  cursor:pointer;
}

&:disabled{
  opacity: 0.6;
}
`

export default AuthButton;