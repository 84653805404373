// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stak_walletAddress{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}

.addres-stak{
    width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Staking/Staking.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".Stak_walletAddress{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    justify-content: flex-start;\n    font-size: 16px;\n    font-weight: 600;\n    color: #000000;\n}\n\n.addres-stak{\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
