import React, { useState, useEffect } from 'react';
// import './Referral.css';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdOutlineContentCopy } from 'react-icons/md';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import Loader from '../../components/Loader/Loader';
import './Staking.css'
import TxnTable from './TxnTable';
import { getMontsFromDurationIndex } from '../../Utils/Utils';

export default function StakingDetails() {
    const navigate = useNavigate();
    const location = useLocation()
    const [copy, setCopy] = useState(false);
    const [status, setStatus] = useState('Pending');
    const [stakeById, setStakeById] = useState({})

    const [loader, setLoader] = useState(false)

    const handleCopy = async (address) => {
        if (address) {
            await navigator?.clipboard?.writeText(address);
            setCopy(true);
        }
    };

    const getStakeById = async () => {
        let id = location.state;
        setLoader(true)
        try {
            let response = await axios.get(`${URLS.getAllStakes}/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
            setStakeById(response?.data?.data);
            setLoader(false)
        } catch (error) {
        }
    }

    useEffect(() => {
        getStakeById()
    }, [location.state])

    const getDurationInSecond = (durationIndex) => {
        let oneMonthsSecond = 86400 * 30;
        switch (durationIndex) {
            case '0':
                return oneMonthsSecond * 3
            case '1':
                return oneMonthsSecond * 5
            case '2':
                return oneMonthsSecond * 8
            case '3':
                return oneMonthsSecond * 12
            case '4':
                return oneMonthsSecond * 24
            default:
                break;
        }
    }
    let OneDayBuffer = 1 * 86400;
    let unlockDateTimeStamp = Number(stakeById?.startTimestamp) + getDurationInSecond(stakeById?.durationIndex) + OneDayBuffer;
    const unlockDate = new Date(unlockDateTimeStamp * 1000);
    const formattedUnlockDate = unlockDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
    const stakeDtae = new Date(stakeById?.startTimestamp * 1000);
    const formattedStakeTimeStamp = stakeDtae.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    if (loader) return <Loader />
    return (
        <>
            <div className="referralDetails_container">
                <div className="refferal_header">
                    <p>
                        <span onClick={() => navigate(-1)}>
                            <GoArrowLeft fontSize={27} />
                        </span>
                        <span className='ms-3'>Farm Detail</span>
                    </p>
                </div>
                <div className='referral_mainBox'>
                    <div className='row h-100 d-flex'>
                        <div className='d-flex flex-column gap-3 mb-5'>
                            <div className='Stak_walletAddress'>
                                <span className='addres-stak'>User Address :</span>
                                <div>
                                    <span className='dekstop-text'>{(stakeById?.userAddress)}</span>
                                    <span onClick={() => handleCopy(stakeById?.userAddress)}
                                        onMouseLeave={() => setCopy(false)} data-tooltip-id="my-tooltip-2" className='copyaddicon'>
                                        <MdOutlineContentCopy className='mx-2' fontSize={20} />
                                    </span>
                                    <ReactTooltip
                                        id="my-tooltip-2"
                                        place="top"
                                        variant="dark"
                                        content={copy ? 'copied' : 'copy'}
                                    />
                                </div>

                            </div>
                            <div className='Stak_walletAddress'>
                                <span className='addres-stak'>Txn Hash :</span>
                                <div>
                                    <span className='s-1'>
                                        {stakeById?.txnHash && stakeById.txnHash.length > 40
                                            ? `${stakeById.txnHash.substring(0, 40)}...`
                                            : stakeById?.txnHash}
                                    </span>
                                    <span onClick={() => handleCopy(stakeById?.txnHash)}
                                        onMouseLeave={() => setCopy(false)} data-tooltip-id="my-tooltip-2" className='copyaddicon'>
                                        <MdOutlineContentCopy className='mx-2' fontSize={20} />
                                    </span>
                                    <ReactTooltip
                                        id="my-tooltip-2"
                                        place="top"
                                        variant="dark"
                                        content={copy ? 'copied' : 'copy'}
                                    />
                                </div>
                            </div>
                            <div className="Stak_walletAddress">
                                <span className='addres-stak'>Farmed Pair :</span>
                                <div>{`${stakeById?.stakedPair}`}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12 d-flex flex-column gap-3'>
                            <div className="Affi_dataBox">
                                <span>Farm Amount :</span> <span>{`${stakeById?.stakeAmount}`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Farm APR :</span> <span>{`${stakeById?.apy}`}</span>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12 d-flex flex-column gap-3 box-2 wallet-addr'>
                            <div className="Affi_dataBox">
                                <span>Farm Status :</span> <span className='text-capitalize'>{`${stakeById?.stakestatus === 'stake' ? 'Farmed' : ''}`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Unlock Date :</span> <span>{`${formattedUnlockDate}`}</span>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12 d-flex flex-column gap-3 box-2 wallet-addr'>
                            <div className="Affi_dataBox">
                                <span>Farm Date :</span> <span>{`${formattedStakeTimeStamp}`}</span>
                            </div>
                            <div className="Affi_dataBox">
                                <span>Farm Period :</span> <span>{`${getMontsFromDurationIndex(stakeById?.durationIndex)}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <TxnTable />
            </div>
        </>
    );
}
