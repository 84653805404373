import React, { Fragment, useEffect, useState } from 'react';
import './News.css';
import { TbCircleArrowRight } from "react-icons/tb";
import { FaCaretLeft, FaCaretRight, FaRegCirclePlay } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import Loader from '../../components/Loader/Loader';
import DOMPurify from 'dompurify';
import NewsDetails from './NewsDetails';
import LoaderSpinner from '../../components/Spinner/LoaderSpinner';
import userEvent from '@testing-library/user-event';

const News = () => {
    const navigate = useNavigate();
    const [activePageNo, setActivePageNo] = useState(1);
    const [newDetails, setNewsDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const itemsPerPage = 6;
    const [descriptionLength, setDescriptionLength] = useState(43);
    const [headingLength, setHeadingLength] = useState(40)
    const [isCenter, setIsCenter] = useState(true)

    const handleActivePageBtn = (pageNo) => setActivePageNo(pageNo);

    const handleNavigate = (type, id) => navigate(`news-details`, { state: { id } });

    const getNews = async () => {
        setLoader(true);
        try {
            const res = await axios.get(URLS.getNewss, {
                headers: { Authorization: `Bearer ${localStorage.getItem('acessToken')}` }
            });
            setNewsDetails(res?.data?.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoader(false);
        }
    };


    const decodeHtmlEntities = (encodedString) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    };

    const sanitizeHtml = (htmlString) => DOMPurify.sanitize(htmlString);

    const getVideoId = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match && match[1];
    };

    // useEffect(() => {
    //     getNews();
    //     const updateDescriptionLength = () => {

    //         if (window.innerWidth >= 1469) {
    //             setDescriptionLength(40);
    //         }
    //         else if (window.innerWidth >= 1300 && window.innerWidth < 1469) {
    //             setDescriptionLength(38);
    //         }
    //         else if (window.innerWidth >= 1250 && window.innerWidth < 1300) {
    //             setDescriptionLength(35);
    //         }
    //         else if (window.innerWidth >= 1100 && window.innerWidth < 1250) {
    //             setDescriptionLength(40);
    //         }
    //         else if (window.innerWidth >= 969 && window.innerWidth < 1100) {
    //             setDescriptionLength(40);
    //         }
    //         else {
    //             setDescriptionLength(50);
    //         }
    //     };

    //     const updateHeadingLength = () => {
    //         if (window.innerWidth >= 1460) {
    //             setHeadingLength(42);
    //         } else if (window.innerWidth >= 1300) {
    //             setHeadingLength(35);
    //         } else if (window.innerWidth >= 1000) {
    //             setHeadingLength(30);
    //         }
    //         else if (window.innerWidth >= 964) {
    //             setHeadingLength(28)
    //         }
    //         else {
    //             setHeadingLength(80)
    //         }
    //     };

    //     updateDescriptionLength();
    //     updateHeadingLength();
    //     window.addEventListener('resize', updateDescriptionLength);
    //     window.addEventListener('resize', updateHeadingLength);
    //     return () => {
    //         window.removeEventListener('resize', updateDescriptionLength);
    //         window.removeEventListener('resize', updateHeadingLength);
    //     }
    // }, []);

    useEffect(() => {
        getNews();

        const handleResize = () => {
            const width = window.innerWidth;
            if (width >= 1469) setDescriptionLength(40);
            else if (width >= 1300) setDescriptionLength(38);
            else if (width >= 1250) setDescriptionLength(35);
            else setDescriptionLength(50);

            if (width >= 1460) setHeadingLength(42);
            else if (width >= 1300) setHeadingLength(35);
            else if (width >= 1000) setHeadingLength(30);
            else if (width >= 964) setHeadingLength(28);
            else setHeadingLength(80);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    let sortedData =
        newDetails.sort((a, b) => { return new Date(b.updatedAt) - new Date(a.updatedAt) });

    useEffect(() => {
        setNewsDetails(sortedData)
        if (newDetails.length < 3) {
            setIsCenter(false)
        }
    }, [])

    const totalPages = Math.ceil(newDetails.length / itemsPerPage);
    const paginatedNews = newDetails.slice((activePageNo - 1) * itemsPerPage, activePageNo * itemsPerPage);

    const startEntry = (activePageNo - 1) * itemsPerPage + 1;
    const endEntry = Math.min(activePageNo * itemsPerPage, newDetails.length);

    const encodehtmlString = (text) => {
        let maxLen = text.slice(0, headingLength) + '...';
        let res = text.length > headingLength ? maxLen : text
        return res;
    }

    const encodeHmlDescription = (text) => {
        let maxLen = text.slice(0, descriptionLength) + '...'
        let res = text.length > descriptionLength ? maxLen : text
        return res
    }

    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className={`news_container ${isCenter ? `hello` : ''} `} >
                        {!newDetails.length && <div className='d-flex justify-content-center w-100'> <span className='noPostFound '>No News Found</span></div>}
                        {
                            paginatedNews.map((items, index) => (
                                <div key={index} className="newsCard  " role='button' onClick={() => handleNavigate(items?.type, items?._id)}>
                                    <h1>{encodehtmlString(items?.heading)}</h1>
                                    <div className="newsImg_section">
                                        {items?.imgurl && <img src={items?.imgurl} alt="photo thumb" />}
                                        {items?.youtubeLink && <iframe className='video_iframe' width="100%" title='video' height="100%" src={`https://www.youtube.com/embed/${getVideoId(items?.youtubeLink)}`} frameBorder="0" allowFullScreen />}
                                    </div>
                                    <div className="newsparaSection">
                                        {items?.description ? (

                                            <div className='' dangerouslySetInnerHTML={{ __html: encodeHmlDescription(sanitizeHtml(decodeHtmlEntities(items?.description))) }} />
                                        ) : (
                                            <p>No description available.</p>
                                        )}

                                        <p>Posted On:  {formatDate(items?.updatedAt?.split('T')[0])} </p>
                                    </div>
                                    <div onClick={() => handleNavigate(items?.type, items?._id)} className="newsViewMoreSec">
                                        {items?.imgurl && <span>View More<TbCircleArrowRight color='#234B9A' fontSize={27} /></span>}
                                        {items?.youtubeLink && <> <span>Watch Now</span><span className=''><FaRegCirclePlay color='#234B9A' fontSize={27} /></span></>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    {totalPages > 1 && (
                        <div className="newFooterPagination d-flex flex-wrap gap-1 justify-content-between">
                            <span>Showing {startEntry} to {endEntry} of {newDetails.length} Entries</span>
                            <div className="newPaginationBtnGrp d-flex align-items-center">
                                <button className='newsPaginationBtn pre' onClick={() => handleActivePageBtn(activePageNo - 1)} disabled={activePageNo === 1}>
                                    Pre
                                </button>
                                {
                                    [...Array(totalPages).keys()].map((_, index) => (
                                        <button key={index} onClick={() => handleActivePageBtn(index + 1)} className={`newsPaginationBtn mid ${activePageNo === index + 1 && 'activePaginationbtn'}`}>
                                            {index + 1}
                                        </button>
                                    ))
                                }
                                <button className='newsPaginationBtn next' onClick={() => handleActivePageBtn(activePageNo + 1)} disabled={activePageNo === totalPages}>
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </>
    );
};

export default News;
