import React, { useEffect, useState } from 'react';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import conciseAddress from '../../Utils/ConciseAddress';

export default function LeadershipRank() {
  const navigate = useNavigate();
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [loader, setLoader] = useState(false);
  const [leadershipBoardData, setLeadershipBoardData] = useState([]);
  const [refFilterData, setRefFilterData] = useState([]);

  useEffect(() => {
    fetchLeadershipBoardData(1);
  }, []);

  useEffect(() => {
    setRefFilterData(leadershipBoardData);
  }, [leadershipBoardData]);

  const fetchLeadershipBoardData = async (page=1, size=10) => {
    setLoader(true);
    try {
      const { data } = await axios.get(`${URLS.getLeadershipRank(page, size)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      setLeadershipBoardData(data?.data?.result || []);
      setTotalResult(data?.data?.totalResult)
      setTotalPages(Math.max(1, Math.ceil((data?.data?.totalResult || 0) / 10)));
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handlePageNumber = (pageNo) => {
    if (pageNo >= 1 && pageNo <= totalPages) {
      setActivePageNo(pageNo);
      fetchLeadershipBoardData(pageNo);
    }
  };

  const handleRedirectToDetailsPage = (level) => {
    navigate(`/affiliate/leaderboard-rank-details`, { state: level });
  };

  const handleFilterTable = (e) => {
    const filterText = e.target?.value?.toLowerCase();
    if (!filterText) {
      setRefFilterData(leadershipBoardData);
    } else {
      const filteredData = leadershipBoardData.filter(({ rank, username, userWalletAddress, allReferral, totalEarning }) =>
        rank?.toString()?.toLowerCase()?.includes(filterText) ||
        username?.toString()?.toLowerCase()?.includes(filterText) ||
        userWalletAddress?.toString()?.toLowerCase()?.includes(filterText) ||
        allReferral?.toString()?.toLowerCase()?.includes(filterText) ||
        totalEarning?.toString()?.toLowerCase()?.includes(filterText)
    );
      setRefFilterData(filteredData);
    }
  };

  if(loader) return <Loader />;
  return (
    <>
      <div className="referral_container">
        <div className="referralTable">
          <h1>LeadershipBoard Rank</h1>
          <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
            <input type="text" placeholder='Search' onChange={handleFilterTable} />
          </div>
          <div className='w-100 responsiveTableParent'>
            <table className='w-100'>
              <thead className='affTableHeader'>
                <tr>
                  <th className='boder-Rght'>Rank</th>
                  <th className='boder-Rght'>Username</th>
                  <th className='boder-Rght'>Wallet Address</th>
                  <th className='boder-Rght'>No. of Referral</th>
                  <th className='boder-Rght'>Total Earn</th>
                  <th className='boder-Rght'>View</th>
                </tr>
              </thead>
              <tbody className='referralTableBody'>
                {refFilterData.length > 0 ? (
                  refFilterData.map((referral) => (
                    <tr key={referral?._id}>
                      <td>{referral?.rank}</td>
                      <td>{referral?.username}</td>
                      <td>{conciseAddress(referral?.userWalletAddress)}</td>
                      <td>{referral?.allReferral}</td>
                      <td>{referral?.totalEarning}</td>
                      <td>
                        <span onClick={() => handleRedirectToDetailsPage(referral)}>
                          <IoEyeOutline fontSize={20} />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='w-100 d-flex justify-content-between'>
            <span className='fw-bold'>Showing {activePageNo} to {totalPages} of {totalResult} Entries</span>
            <div className='AffTxTableButton'>
              <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                <span>Pre</span>
              </button>
              {((totalPages > 0) ? [...Array(totalPages).keys()] : [...Array(1).keys()])
              .slice(Math.max(0, activePageNo - 3), Math.min(totalPages, activePageNo + 2))
              .map((_, index) => (
                  <button
                      className={`${activePageNo === index + Math.max(0, activePageNo - 3) + 1 && 'activePaginationbtn'}`}
                      key={index + Math.max(0, activePageNo - 3) + 1}
                      onClick={() => handlePageNumber(index + Math.max(0, activePageNo - 3) + 1)}
                  >
                      {index + Math.max(0, activePageNo - 3) + 1}
                  </button>
              ))}
              <button disabled={activePageNo === totalPages} onClick={() => handlePageNumber(activePageNo + 1)}>
                <span>Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}