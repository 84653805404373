// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Migration_Dashboard_Bg_1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .layout_main {
  width: 100%;
  height: fit-content;
  background-image: url("../../assets/Migration_Dashboard_Bg_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
} */

#root{
  /* height: 100%;
  width: 100%; */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
}
.sidebar {
  width: 300px;
  height: 100%;
  background-color: rgb(231, 126, 126);
}

.headerWithOutlet {
  width: 100%;
  height: 100%;
  /* background-image: url("../../assets/layoutbg111.png");
  background-repeat: no-repeat;
  background-size: cover; */
}

.lay_topHeader {
  background-color: transparent;
  width: 100%;
}

.adminOutlet {
  width: 100%;
  padding: 8px 17px;
  overflow-y: auto;
}

.adminOutlet::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width:1401px) {
 
}
@media screen and (max-width:1230px) {
  .adminOutlet{
    padding: 1rem 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Layout/Layout.css"],"names":[],"mappings":"AAAA;;;;;;;GAOG;;AAEH;EACE;gBACc;EACd,yDAAkE;EAClE,4BAA4B;EAC5B,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ;;2BAEyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;;AAEA;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/* .layout_main {\n  width: 100%;\n  height: fit-content;\n  background-image: url(\"../../assets/Migration_Dashboard_Bg_1.png\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  overflow-y: auto;\n} */\n\n#root{\n  /* height: 100%;\n  width: 100%; */\n  background-image: url(\"../../assets/Migration_Dashboard_Bg_1.png\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  overflow-y: auto;\n}\n.sidebar {\n  width: 300px;\n  height: 100%;\n  background-color: rgb(231, 126, 126);\n}\n\n.headerWithOutlet {\n  width: 100%;\n  height: 100%;\n  /* background-image: url(\"../../assets/layoutbg111.png\");\n  background-repeat: no-repeat;\n  background-size: cover; */\n}\n\n.lay_topHeader {\n  background-color: transparent;\n  width: 100%;\n}\n\n.adminOutlet {\n  width: 100%;\n  padding: 8px 17px;\n  overflow-y: auto;\n}\n\n.adminOutlet::-webkit-scrollbar {\n  display: none;\n}\n\n@media screen and (max-width:1401px) {\n \n}\n@media screen and (max-width:1230px) {\n  .adminOutlet{\n    padding: 1rem 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
