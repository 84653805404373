import React, { useContext, useEffect, useState } from 'react'
// @ts-ignore
import OTPInput from "otp-input-react";

import './OtpModal.css'
import AuthButton from '../Buttons/Buttons';
import UserContext from '../ContextApi/UserContext';
import { IoClose } from "react-icons/io5";
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { errorPopup, successPopup } from '../../Utils/ToasterMessage';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';


export default function LoginOtpModal({ auth, userDetails }) {
    const { loginOtpModal, setLoginOtpModal } = useContext(UserContext)
    const navigate = useNavigate()
    const [OTP, setOTP] = useState(0)
    const [resentTime, setResentTime] = useState(30)
    const [disabledResendBtn, setDisabledResendBtn] = useState(false)
    const [loginAuth, setLoginAuth] = useState({})
    const [loader, setLoader] = useState(false)

    const handleResentOtp = () => {
        handleResendOtp();
        setDisabledResendBtn(true)
        let timesRun = 30;
        let interval = setInterval(function () {
            timesRun -= 1;
            setResentTime(timesRun)
            if (timesRun === 0) {
                clearInterval(interval);
                setDisabledResendBtn(false)
            }
        }, 1000);
    }

    const handleResendOtp = async () => {
        setLoader(true)
        await axios.post(URLS.login, userDetails)
            .then(res => {
                successPopup(res?.data?.message);
                setLoginAuth(res?.data?.data);
            })
            .catch(err => {
                errorPopup(err?.response?.data?.message);
                setLoader(true);
            });
    }

    const handleSubmit = () => {
        setLoader(true)
        axios.post(URLS.twoFactAuth, {
            // @ts-ignore
            "iv": loginAuth.iv,
            // @ts-ignore
            "content": loginAuth.content,
            "otp": OTP
        })
            .then(res => {
                successPopup(res?.data?.message)
                setLoginOtpModal(false)
                setLoader(false)
                navigateToHomePage();
                localStorage.setItem('acessToken', res?.data?.data?.tokens?.access?.token)

            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message)
                setLoader(false)
            })
    }

    const navigateToHomePage = () => {
        const interval = setTimeout(() => {
            navigate('/home')
        }, 1000)
        return () => clearTimeout(interval)
    }

    useEffect(()=>{
        setLoginAuth(auth)
    },[auth])

    return (
        <div className={`${loginOtpModal ? 'd-block' : 'd-none'}`}>
            <div className={`loginOtpModal`}>
                <div className="otp_container">
                    <div className='m-0 closeModal' onClick={() => setLoginOtpModal(false)}><IoClose /> </div>
                    <h1 className='text-center mb-4'>Enter OTP to Verify</h1>
                    <div className='otp-input-box mb-2'>
                        <OTPInput value={OTP} onChange={setOTP} autoFocus={true} OTPLength={4} otpType="number" disabled={false} />
                    </div>
                    <p className='otpreset mt-2'>Not received the OTP? <button disabled={disabledResendBtn === true ? true : false} onClick={handleResentOtp}>Resend</button></p>
                    <p className='text-center'>{(resentTime < 30 && resentTime > 0) && `OTP Resend after ${resentTime} seconds`}</p>
                    <AuthButton disabled={loader} style={{ width: '90%' }} onClick={handleSubmit} >
                    {loader && <Spinner animation="border" />}
                        Submit
                    </AuthButton>
                </div>
            </div>

        </div>
    )
}
