// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .loader-container{
background-image: linear-gradient( rgba(0, 0, 0, 0.153),rgba(0, 0, 0, 0.158));   
background-position: center;
background-repeat: no-repeat;
background-size: cover;
} */

`, "",{"version":3,"sources":["webpack://./src/components/Spinner/LoaderSpinner.css"],"names":[],"mappings":"AAAA;;;;;GAKG","sourcesContent":["/* .loader-container{\nbackground-image: linear-gradient( rgba(0, 0, 0, 0.153),rgba(0, 0, 0, 0.158));   \nbackground-position: center;\nbackground-repeat: no-repeat;\nbackground-size: cover;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
