import React, { useEffect, useRef, useState } from 'react'
import './AddNews.css'
import '../EditArticles.css'

import { GoArrowLeft } from 'react-icons/go'
import { IoImagesOutline } from "react-icons/io5";
import { Editor } from 'primereact/editor';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import AuthButton from '../../../components/Buttons/Buttons';
import axios from 'axios';
import { URLS } from '../../../Utils/app_urls';
import { successPopup } from '../../../Utils/ToasterMessage';
import Loader from '../../../components/Loader/Loader';


export default function AddNews() {
  const navigate = useNavigate()
  const [blogHtmlDes, setBlogHtmlDes] = useState('');
  const [newsTitle, setNewsTitle] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [fileName, setFileName] = useState('')
  const [newsType, setNewsType] = useState(true)
  const [videoUrlLink, setVideoUrlLink] = useState('')
  const [newsName, setNewsName] = useState('')
  const [loader, setLoader] = useState(false)

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});

  const handleChangeValildation = (field, e) => {
    setErrors({})
    setFields({
      ...fields,
      [field]: e.target.value,
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    // if ((fields["newsName"] || '').slice() === '') {
    //   formIsValid = false;
    //   errors["newsName"] = "News name can not be empty.";
    // }
    if ((fields["newsTitle"] || '').slice() === '') {
      formIsValid = false;
      errors["newsTitle"] = "News title address can not be empty.";
    }
    if (newsType) {
      if ((fields["imageUrl"] || imageUrl || fileName || '').slice() === '') {
        formIsValid = false;
        errors["imageUrl"] = "image can not be empty.";
      }
    } else {
      if ((fields["youtubeLink"] || '').slice() === '') {
        formIsValid = false;
        errors["youtubeLink"] = "Video Url can not be empty.";
      }
    }
    if ((fields["blogDescription"] || '\n').slice() === '\n') {
      formIsValid = false;
      errors["blogDescription"] = "Content of News can not be empty";
    }


    setErrors(errors)
    return formIsValid;
  }

  const fileUplodRef = useRef(null)

  const handleEditorOnChange = (value) => {
    
    setBlogHtmlDes(value.textValue);
    let e={
      target:{
          value:value.textValue
      }
    }
    handleChangeValildation('blogDescription',e)

  }

  const handlefileUpload = () => {
    fileUplodRef.current.click();
  }

  const handleNewsTypeChange = (e) => {
    setNewsType(e.target.value === 'true')
  }

  const uploadImage = async () => {
    if (handleValidation()) {
      const formData = new FormData();
      formData.append('imageUrl', imageUrl);
      if (imageUrl) {
        const res = await axios.post(URLS.uploadImage, formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('acessToken')}`
            },
          })
          .then(res => {
            return res;
          })
          .catch(err => {
          })
        return res;
      }
    }
  }
  const uploadBlogPost = async () => {
    if (handleValidation()) {
      setLoader(true)
      const S3ImageUrl = await uploadImage()
      const postBody =
        newsType ? {
          // "name": newsName,
          "heading": `${newsTitle}`,
          "imgurl": S3ImageUrl?.data?.data,
          "description": blogHtmlDes,
          "type": newsType ? 'blog' : 'youtube'
        } :
          {
            // "name": newsName,
            "heading": `${newsTitle}`,
            "type": newsType ? 'blog' : 'youtube',
            "description": blogHtmlDes,
            "youtubeLink": videoUrlLink
          }

      await axios.post(URLS.postNews, postBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('acessToken')}`
          }
        })
        .then((res) => {
          successPopup(res?.data?.message);
          setImageUrl('');
          setNewsTitle('')
          setFileName('')
          setLoader(false)
          setBlogHtmlDes('')
          setVideoUrlLink('')

        })
        .catch((err) => {
          setLoader(false)
        })
    }
  }

  useEffect(() => {

  }, [])


  
  const header = (
    <span className="ql-formats">
        <select className="ql-header">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option value="3">Normal</option>
        </select>
        <select className="ql-size">
            <option value="small">Small</option>
            <option defaultValue>Normal</option>
            <option value="large">Large</option>
            <option value="huge">Huge</option>
        </select>
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-link" aria-label="Link"></button>
    </span>
  );

  if(loader) return <Loader />;
  return (
    <>
      <div className="editTextArticle">
        <h1 onClick={() => navigate('/news')}><GoArrowLeft fontSize={20} /> <span className='ms-2 news_heading'>Add News</span></h1>
        <div className='newTypeGroup'>
          <div className='newTypeGroup gap-5'>
            <div onClick={() => setNewsType(true)}>
              <input
                value={true}
                checked={newsType === true}
                name='articleType'
                type="radio"
                id='inline-radio-1'
                onChange={handleNewsTypeChange}
              />
              <label htmlFor="inline-radio-1 " className='article'>Article</label>
            </div>
            <div onClick={() => setNewsType(false)}>
              <input
                value={false}
                checked={newsType === false}
                name='articleType'
                type="radio"
                id='inline-radio-2'
                onChange={handleNewsTypeChange}
              />
              <label htmlFor="inline-radio-2" className='youtube'>YouTube</label>
            </div>
          </div>
      
        </div>
        {/* <div className="newsTitle">
          <p className='m-0 mb-2'>News Name</p>
          <input
            className='newsTitleInput'
            value={newsName}
            placeholder='Enter News Name'
            onChange={(e) => {
              setNewsName(e.target.value)
              handleChangeValildation('newsName', e)
            }}
            type="text"
          />
          <span style={{ color: "#f21519" }}>{errors["newsName"]}</span>
        </div> */}
        <div className="newsTitle">
          <p className='m-0 mb-2'>News Title</p>
          <input
            className='newsTitleInput'
            value={newsTitle}
            placeholder='Type a title for the news'
            onChange={(e) => {
              setNewsTitle(e.target.value)
              handleChangeValildation('newsTitle', e)
            }}
            type="text"
          />
          <span style={{ color: "#f21519" }}>{errors["newsTitle"]}</span>
        </div>
        {!newsType ? <div className="newsTitle">
          <p className='m-0 mb-2'>News Video Url Link</p>
          <input
            className='newsTitleInput'
            value={videoUrlLink}
            placeholder='Paste your news video link'
            onChange={(e) => {
              setVideoUrlLink(e.target.value)
              handleChangeValildation('youtubeLink', e)
            }}
            type="text"
          />
          <span style={{ color: "#f21519" }}>{errors["youtubeLink"]}</span>
        </div>

          : <div className="newsTitle">
            <p className='m-0 mb-2'>Image for News</p>
            <div className='uploadImageIcon'>
              <input
                className=''
                value={fileName}
                onChange={(e) => {
                  setFileName(e.target.value)
                  handleChangeValildation('imageUrl', e)
                }}
                type="text"
                placeholder='Browse image or drop here'
                disabled
              />
              <input accept='.png, .svg, .jpeg, .jpg' className='fileUploadMainInput'
                onChange={(e) => {
                  setImageUrl(e.target.files[0])
                  setFileName(e.target.value)
                  handleChangeValildation('imageUrl', e)
                }}
                ref={fileUplodRef} type="file" />
              <span onClick={() => handlefileUpload()}><IoImagesOutline fontSize={30} /></span>
            </div>
            <span style={{ color: "#f21519" }}>{errors["imageUrl"]}</span>
          </div>}
        <div className='textArticleEditorBox'>
          <h1>Content of News</h1>
          <Editor placeholder={'Type your news discription...'}
          headerTemplate={header}
          value={blogHtmlDes} onTextChange={handleEditorOnChange} 
          style={{ height: '255px', fontSize: "16px" }} 
          />
          <span style={{ color: "#f21519" }}>{errors["blogDescription"]}</span>
        </div>
        <div className='publishButton d-flex justify-content-center'>
        <AuthButton onClick={uploadBlogPost} style={{ width: '507px' }}>Publish</AuthButton>
        </div>
      </div>
    </>
  )
}
