import React, { useContext, useState } from 'react';
import magnumMataLogo from '../../assets/magnumMetaLogo.png';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import AuthButton from '../Buttons/Buttons';
import UserContext from '../ContextApi/UserContext';
import { URLS } from '../../Utils/app_urls';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { errorPopup, successPopup } from '../../Utils/ToasterMessage';
import { Spinner } from 'react-bootstrap';
import './Auth.css';

export default function ChangePassword() {
    const navigate = useNavigate();
    const { setLoginOtpModal } = useContext(UserContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [loginAuth, setLoginAuth] = useState({});
    const [loader, setLoader] = useState(false);

    const formik = useFormik({
        initialValues: { currentPassword: '', newPassword: '', confirmnewpassword:''},
        validationSchema: Yup.object({
            currentPassword: Yup.string().required('Please Enter your Current password'),
                // .matches(
                //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                // ),
                newPassword: Yup.string().required('Please Enter your new password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                ), 
                confirmnewpassword: Yup.string().required('Confirm new password')
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        }),
        onSubmit: async (userDetails) => {
            // console.log("userDetails:",userDetails);
            setLoader(true);
            try {
                const res = await axios.post(URLS.updatePassword, {
                    currentPassword: userDetails.currentPassword,
                    newPassword: userDetails.newPassword,
                },{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                    }
                });
                successPopup(res?.data?.message);
                setLoader(false);
                setTimeout(() => {
                    localStorage.removeItem("acessToken")
                    navigate('/login')
                }, 1500);
                // handleRedirectToHome()
                // setLoginAuth(res?.data?.data);
            } catch (err) {
                errorPopup(err?.response?.data?.message);
            } finally {
                setLoader(false);
            }
        }
    });

    // const handleRedirectToHome = () => {
    //     navigate(`/home`);
    //   };
    return (
        <div className='position-relative'>
            <div className="changepassword_main">
                <div className="login_container">
                    {/* <img src={magnumMataLogo} alt="Magnum Meta Logo" loading="lazy" /> */}
                    <div className="inputContainer mt-3">
                        <h1 className='mb-4'>Change Admin Password</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='d-flex flex-column userInput mb-4'>
                                <label htmlFor="currentPassword">Current Password</label>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <input
                                        id='currentPassword'
                                        name='currentPassword'
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Enter your current password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.currentPassword}
                                        className={formik.errors.currentPassword && formik.touched.currentPassword ? 'input-error' : ''}
                                    />
                                    <span role="button" onClick={() => setShowPassword(prev => !prev)}>
                                        {showPassword ? <IoEyeOffOutline fontSize={'22px'} /> : <IoEyeOutline fontSize={'22px'} />}
                                    </span>
                                </div>
                                {formik.errors.currentPassword && formik.touched.currentPassword && (
                                    <span className="input-feedback text-danger auth-error text-start">{formik.errors.currentPassword}</span>
                                )}
                            </div>
                            <div className='d-flex flex-column userInput mb-4'>
                                <label htmlFor="newPassword">Create New Password</label>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <input
                                        id='newPassword'
                                        name='newPassword'
                                        type={showNewPassword ? 'text' : 'password'}
                                        placeholder='Enter your new password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.newPassword}
                                        className={formik.errors.newPassword && formik.touched.newPassword ? 'input-error' : ''}
                                    />
                                    <span role="button" onClick={() => setShowNewPassword(prev => !prev)}>
                                        {showNewPassword ? <IoEyeOffOutline fontSize={'22px'} /> : <IoEyeOutline fontSize={'22px'} />}
                                    </span>
                                </div>
                                {formik.errors.newPassword && formik.touched.newPassword && (
                                    <span className="input-feedback text-danger auth-error text-start">{formik.errors.newPassword}</span>
                                )}
                            </div>
                            <div className='d-flex flex-column userInput'>
                                <label htmlFor="confirmnewpassword">Confirm  New Password</label>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <input
                                        id='confirmnewpassword'
                                        name='confirmnewpassword'
                                        type={showConfirmNewPassword ? 'text' : 'password'}
                                        placeholder='Confirm new password'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmnewpassword}
                                        className={formik.errors.confirmnewpassword && formik.touched.confirmnewpassword ? 'input-error' : ''}
                                    />
                                    <span role="button" onClick={() => setShowConfirmNewPassword(prev => !prev)}>
                                        {showConfirmNewPassword ? <IoEyeOffOutline fontSize={'22px'} /> : <IoEyeOutline fontSize={'22px'} />}
                                    </span>
                                </div>
                                {formik.errors.confirmnewpassword && formik.touched.confirmnewpassword && (
                                    <span className="input-feedback text-danger auth-error text-start">{formik.errors.confirmnewpassword}</span>
                                )}
                              
                            </div>
                            <AuthButton disabled={loader} type='submit'  className='mt-4'>
                                {loader && <Spinner animation="border" />} Submit
                            </AuthButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
