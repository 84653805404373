// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/modalbg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.txMigaration_mainModal{
    width: 100%;
    height: 100vh;
    z-index: 999999;
    background-color: #00000030;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tx_table_modalBox{
    width: 650px;
    height: 450px;
    border-radius: 30px;
    background-color: #ffffff;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
}

.tx_table_modalBox > h1{
    font-size: 24px;
    font-weight: 600;
}

.tx_table_modalBox > div:first-child > span{
    cursor: pointer;
}

.mgr-mdl-tbl tr > td:first-child{
    width: 200px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 4px;
}
.mgr-mdl-tbl tr > td:last-child{
    /* width: 200px; */
    color: #515151;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 4px;
    word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/components/PopUpModal/TxMigrationTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,2BAA2B;IAC3B,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,yDAAiD;IACjD,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".txMigaration_mainModal{\n    width: 100%;\n    height: 100vh;\n    z-index: 999999;\n    background-color: #00000030;\n    position: fixed;\n    top: 0;\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.tx_table_modalBox{\n    width: 650px;\n    height: 450px;\n    border-radius: 30px;\n    background-color: #ffffff;\n    background-image: url('../../assets/modalbg.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.tx_table_modalBox > h1{\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.tx_table_modalBox > div:first-child > span{\n    cursor: pointer;\n}\n\n.mgr-mdl-tbl tr > td:first-child{\n    width: 200px;\n    color: #000000;\n    font-size: 16px;\n    font-weight: 500;\n    padding: 5px 4px;\n}\n.mgr-mdl-tbl tr > td:last-child{\n    /* width: 200px; */\n    color: #515151;\n    font-size: 16px;\n    font-weight: 500;\n    padding: 5px 4px;\n    word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
