import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useLocation} from 'react-router-dom'
import { URLS } from '../../Utils/app_urls'
import Loader from '../../components/Loader/Loader';
import conciseAddress from '../../Utils/ConciseAddress';
export default function InDirectDetails(){
    const location = useLocation();
    const [useList, setUserList]=useState([])
    const [loader, setLoader]=useState(false);
    const [search, setSearch]=useState('');
    useEffect(()=>{
        const walletAdd = location.state;
        setLoader(true)
        axios.get(URLS.getInDirectUser(walletAdd, 1, 10),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            setUserList(res?.data?.data?.result);
            setLoader(false);
        })
        .catch((err)=>{
            setLoader(false);
        })
    },[location.state])

    const filterData = useList.filter((data)=>{
        return data?.username?.includes(search) ||
        data?.walletaddress?.includes(search) ||
        data?.mforgeToken?.includes(search) ||
        data?.totalClaimed?.includes(search) ||
        data?.totalStake?.includes(search) ||
        data?.claimAbleAmount?.includes(search)
    })

    if(loader) return <Loader/>;

    return(
        <div className="referral_container">
            <div className="referralTable">
            <h1>Secondary Invite Details</h1>
            <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
                <input type="text" placeholder='Search' value={search} onChange={(e)=>{setSearch(e.target.value)}} />
            </div>
            <div className='w-100 responsiveTableParent'>
                <table className='w-100'>
                <thead className='affTableHeader'>
                    <tr>
                    <th className='boder-Rght'>Username</th>
                    <th className='boder-Rght'>Wallet Address</th>
                    <th className='boder-Rght'>mForge Token</th>
                    <th className='boder-Rght'>Total Earn</th>
                    <th className='boder-Rght'>Stakes</th>
                    <th className='boder-Rght'>Next Claim</th>
                    </tr>
                </thead>
                <tbody className='referralTableBody'>
                    {filterData.length > 0 ? (
                        filterData.map((referral) => (
                        <tr key={useList?._id}>
                        <td>{referral?.username}</td>
                        <td>{conciseAddress(referral?.walletaddress)}</td>
                        <td>{referral?.mforgeToken}</td>
                        <td>{referral?.totalClaimed}</td>
                        <td>{referral?.totalStake}</td>
                        <td>{referral?.claimAbleAmount}</td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                    </tr>
                    )}
                </tbody>
                </table>
            </div>
            {/* <div className='w-100 d-flex justify-content-between'>
                <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
                <div className='AffTxTableButton'>
                <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                    <span>Pre</span>
                </button>
                {Array.from({ length: totalPageNo }, (_, index) => (
                    <button
                    className={`${activePageNo === index + 1 ? 'activePaginationbtn' : ''}`}
                    key={index}
                    onClick={() => handlePageNumber(index + 1)}
                    >
                    {index + 1}
                    </button>
                ))}
                <button disabled={activePageNo === totalPageNo} onClick={() => handlePageNumber(activePageNo + 1)}>
                    <span>Next</span>
                </button>
                </div>
            </div> */}
            </div>
        </div>
    );
}