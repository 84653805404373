import React, { useState, useRef, useEffect } from 'react';
import './Referral.css';
import { GoArrowLeft } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthButton, { OutlineButton } from '../../components/Buttons/Buttons';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdOutlineContentCopy } from 'react-icons/md';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import Loader from '../../components/Loader/Loader';
import conciseAddress from '../../Utils/ConciseAddress';

export default function ReferralDetails() {
    const navigate = useNavigate();
    const location = useLocation()
    const [copy, setCopy] = useState(false);
    const [status, setStatus] = useState('Pending');
    const [refferal, setRefferal] = useState({})
    const [loader, setLoader] = useState(false)
    const [isApproveDisabled, setIsApproveDisabled] = useState(false);
    const [isRejectDisabled, setIsRejectDisabled] = useState(false);

    let id = location.state
    const handleCopy = async (address) => {
        if(address) {
            await navigator?.clipboard?.writeText(address);
            setCopy(true);
        }
    };

    const get_singleReferral = async (id) => {
        setLoader(true)
        try {
            let res = await axios.get(`${URLS.getSingleReferral}/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
            setRefferal(res?.data?.data[0])
            setLoader(false)

        } catch (error) {
        }
    }

    const handleStatusChange = (newStatus, address) => {
        setLoader(true)
        if (newStatus === 'approve') {
            setIsApproveDisabled(true);
            setIsRejectDisabled(false);
        } else if (newStatus === 'reject') {
            setIsRejectDisabled(true);
            setIsApproveDisabled(false);
        }
        const statusBody = {
            "refstatus": newStatus,
            "walletAdress": address,
        }

        axios.patch(`${URLS.changeRefStatus}`,
            statusBody,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('acessToken')}`
                }
            })
            .then((res) => {
                setStatus(newStatus);
                get_singleReferral(id)
                setLoader(false)
            })
            .catch((err) => {
                setIsApproveDisabled(false);
                setIsRejectDisabled(false);
            });
    };

    useEffect(() => {
        get_singleReferral(id)
    }, [id])

    if(loader){
        return <Loader/>
    }
    return (
        <div className="referralDetails_container">
            <div className="refferal_header">
                <p >    
                    <span onClick={() => navigate('/affiliate/referral-requests')}>
                        <GoArrowLeft fontSize={27} />
                    </span>
                    <span className='ms-3'>Referral Application Detail</span>
                </p>
            </div>
            <div className='referral_mainBox referral-hei'>
                <div className='d-flex'>
                    <div className='Affi_walletAddress'>
                        <span>Wallet Address :</span>
                    </div>
                    <div className='d-flex fw-bold align-items-center'>
                        <span className='wallet-address'>{refferal?.walletaddress}</span>
                        <span onClick={() => handleCopy(refferal?.walletaddress)}
                            onMouseLeave={() => setCopy(false)} data-tooltip-id="my-tooltip-2" className='copyaddicon'>
                            <MdOutlineContentCopy className='mx-2' fontSize={20} />
                        </span>
                        <ReactTooltip
                            id="my-tooltip-2"
                            place="top"
                            variant="dark"
                            content={copy ? 'copied' : 'copy'}
                        />
                    </div>
                </div>
                <div className='row h-100'>
                    
                    <div className='col-md-6 col-sm-12  box'>
                        <div className="Affi_dataBox">
                            <span>Username :</span> <span className='text-capitalize'>{refferal?.username}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Total Farm Token :</span> <span>{refferal?.totalStakeAmount || '0' }</span>
                        </div>
                        {<div className='button-end'>
                            <OutlineButton
                                style={{ width: '210px' }}
                                onClick={() => handleStatusChange('reject', refferal?.walletaddress)}
                                className={isRejectDisabled ? 'disabled-button' : ''}
                                disabled={refferal?.status === "reject"} >
                                Reject
                            </OutlineButton>
                        </div>}
                    </div> 
                    <div className='col-md-6 col-sm-12 wallet-addr box'>
                        <div className="Affi_dataBox">
                            <span>Status :</span> <span className='text-capitalize'>{refferal?.status === 'approve' ? 'Approved' : refferal?.status === 'reject' ? "Rejected" : 'Pending'}</span>
                        </div>
                        <div className="Affi_dataBox">
                            <span>Requested At :</span> <span>{refferal?.createdAt?.split('T')[0]}</span>
                        </div>
                        <div className='button-end-2'>
                            <AuthButton
                                style={{ width: '210px' }}
                                onClick={() => handleStatusChange('approve', refferal?.walletaddress)}
                                className={isApproveDisabled ? 'disabled-button' : ''}
                                disabled={refferal?.status === "approve"}
                            >
                                Approve
                            </AuthButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
