import React, { useContext, useEffect } from 'react'
import { RxCross2, RxCrossCircled } from "react-icons/rx";
import './TxMigrationTable.css'
import UserContext from '../ContextApi/UserContext';
import conciseAddress from '../../Utils/ConciseAddress';

export default function MigrationTxHistoryModal({ rowData }) {
    const { openMigTxModal, setOpenMigTxModal } = useContext(UserContext)

    useEffect(() => {
    }, [rowData])

    return (
        <>
            <div className={` ${openMigTxModal ? 'd-block' : 'd-none'} `}>
                <div className={`txMigaration_mainModal`}>
                    <div className="tx_table_modalBox p-3">
                        <div style={{ float: 'right' }}><span onClick={() => setOpenMigTxModal(false)}><RxCrossCircled fontSize={25} /></span>  </div>
                        <h1 className='mt-3 mb-4'>Transaction Details :</h1>
                        <table>
                            <tbody className='mgr-mdl-tbl'>
                                <tr><td>TX ID</td><td>{rowData?.hash}</td></tr>
                                <tr><td>Date</td><td>{rowData?.updatedAt?.split('T')[0]}</td></tr>
                                <tr><td>Polygon Wallet Address</td><td>{rowData?.from}</td></tr>
                                <tr><td>Total MGB Token</td><td>{rowData?.totalbalance}</td></tr>
                                <tr><td>Burn MGB Token</td><td>{rowData?.balance}</td></tr>
                                <tr><td>Tx Hash</td><td>{rowData?.transfers?.hash}</td></tr>
                                <tr><td>Binance Wallet Add</td><td>{rowData?.to}</td></tr>
                                <tr><td>MFORGE</td><td>{rowData?.transfers?.balance}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
