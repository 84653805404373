import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { SiPolygon } from "react-icons/si";
import { URLS } from '../../Utils/app_urls';
import { SiBnbchain } from "react-icons/si";
import { MdOutlineContentCopy } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Tooltip as ReactTooltip } from "react-tooltip";
import Loader from '../../components/Loader/Loader';


export default function BalanceCards() {

    const [mgbBalance, setMgbBalance] = useState({})
    const [allBurnTokens, setAllBurnTokens] = useState({})
    const [mForgeBalance, setMforgeBalance] = useState({})
    const [copy, setCopy] = useState(false)
    const [polygonAddress, setPolgonAddress] = useState('')
    const [mForgeAdd, setmForgeAdd] = useState('')
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(null);

    const get_MGB_Balance = () => {
        setLoader(true);
        axios.get(URLS.dash_MGB_Balance, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`
            }
        })
            .then((res) => {
                setMgbBalance(res?.data?.data);
                setPolgonAddress(res?.data?.data?.admin_adress);
                setLoader(false);
            })
            .catch((err) => {
                setError(err);
                setLoader(false);
            });
    }

    const get_all_Burn_Token = () => {
        axios.get(URLS.totalBrn_Trfr_cnt_sum, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`
            }
        })
            .then(res => {
                setAllBurnTokens(res?.data?.data);
            })
            .catch((err) => {
            });
    }

    const get_mForge_Balance = () => {
        axios.get(URLS.dash_MFORGE_Balance, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`
            }
        })
            .then(res => {
                setMforgeBalance(res?.data?.data);
                setmForgeAdd(res?.data?.data?.admin_balance);
            })
            .catch((err) => {
            });
    }
    const handleCopy = async (address) => {
        try {
            await navigator.clipboard.writeText(address);
        } catch (err) {
        }
        setCopy(true)
    }

    useEffect(() => {
        get_MGB_Balance();
        get_mForge_Balance();
        get_all_Burn_Token();
    }, [])

    if(loader) return <Loader />;
    return (
        <>
            {error && (
                <div className="error-message">
                    Failed to fetch data. Please try again later.
                </div>
            )}
            <div className={`balance_cards d-flex flex-row flex-wrap flex-md-nowrap gap-4 ${loader && 'minimizeOpacity'}`}>
                <div className="polygon_card bnb-ntwok-bg">
                    <div className="card_header d-flex align-items-center gap-3">
                        <div className="imgbox">
                            <SiPolygon fontSize={20} color='#fff' />
                        </div>
                        <span>Polygon Network</span>
                    </div>
                    <div className='pynWltAdr'>
                        <span>Admin Wallet Address :</span>
                        <span>{mgbBalance?.admin_adress}
                            <span onClick={() => handleCopy(polygonAddress)} onMouseLeave={() => setCopy(false)} className="ms-2 d-inline-block">
                                <span data-tooltip-id="my-tooltip-2" className='copyaddicon'><MdOutlineContentCopy fontSize={20} /></span>
                            </span>
                            <ReactTooltip
                                id="my-tooltip-2"
                                place="top"
                                variant="dark"
                                content={copy ? 'copied' : 'copy'}
                            />
                            {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{copy ? 'copied' : 'copy'}</Tooltip>}>
                            </OverlayTrigger> */}
                            {/* <span className='ms-2 copyaddicon'><MdOutlineContentCopy fontSize={20} /></span> */}
                        </span>
                    </div>
                    <div className="balnceplycrds d-flex justify-content-between flex-wrap gap-4">
                        <div className="maticBalance">
                            <span>Matic Balance :</span>
                            <span>{Number(mgbBalance?.MATIC_bal || 0).toFixed(6) || 'N/A'}</span>
                        </div>
                        <div className="maticBalance">
                            <span>MGB Token :</span>
                            <span>{Number(mgbBalance?.MGB_bal || 0).toFixed(6) || 'N/A'}</span>
                        </div>
                        <div className="maticBalance">
                            <span>Total Burn :</span>
                            <span>{allBurnTokens?.Total_MGB_burn_count || 'N/A'}</span>
                        </div>
                        <div className="maticBalance">
                            <span>Total Burn Transfer :</span>
                            <span>{Number(allBurnTokens?.Total_MGB_burn || 0).toFixed(6).toString() || 'N/A'}</span>
                        </div>
                    </div>
                </div>

                <div className="polygon_card polygon_card22 bnb-ntwok-bg">
                    <div className="card_header d-flex align-items-center gap-3">
                        <div className="bnbimgbox">
                            <SiBnbchain fontSize={24} color='#fff' />
                        </div>
                        <span>BNB Network</span>
                    </div>
                    <div className='pynWltAdr'>
                        <span>Admin Wallet Address :</span>
                        <span>{mForgeBalance?.admin_balance}
                            <span onClick={() => handleCopy(mForgeAdd)} onMouseLeave={() => setCopy(false)} className="ms-2 d-inline-block">
                                <span data-tooltip-id="my-tooltip-1" className='copyaddicon'><MdOutlineContentCopy fontSize={20} /></span>
                            </span>
                            {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{copy ? 'copied' : 'copy'}</Tooltip>}>
                            </OverlayTrigger> */}
                            <ReactTooltip
                                id="my-tooltip-1"
                                place="top"
                                variant="dark"
                                content={copy ? 'copied' : 'copy'}
                            />
                        </span>
                    </div>
                    <div className="balnceplycrds d-flex justify-content-between flex-wrap gap-4">
                        <div className="maticBalance nbnbgColor">
                            <span>BNB Balance :</span>
                            <span>{Number(mForgeBalance?.BNB_bal || 0).toFixed(6) || 'N/A'}</span>
                        </div>
                        <div className="maticBalance nbnbgColor">
                            <span>MFORGE Token :</span>
                            <span>{Number(mForgeBalance?.MFORGE_bal || 0).toFixed(6) || 'N/A'}</span>
                        </div>
                        <div className="maticBalance nbnbgColor">
                            <span>Total Transfer :</span>
                            <span>{allBurnTokens?.Total_MORGE_burn_count || '0' || 'N/A'}</span>
                        </div>
                        <div className="maticBalance nbnbgColor">
                            <span>Total Transfer Transaction :</span>
                            <span>{Number(allBurnTokens?.Total_MGB_burn_transfer || 0).toFixed(6) || 'N/A'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
