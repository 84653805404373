import React from 'react'
import './Index.css'
import BalanceCards from './BalanceCards'
import TransactionTable from './TransactionTable'

export default function Home() {
    return (
        <>
            <div className="home_main d-flex flex-column gap-4">
                <BalanceCards/>
                <TransactionTable/>
            </div>
        </>
    )
}
