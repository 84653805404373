let AllEnvApiUrls = {
    production: {
        baseUrl: process.env.REACT_APP_PROD_API_URL,
    },
    staging: {
        baseUrl: process.env.REACT_APP_DEV_API_URL,
    },
    development: {
        baseUrl: process.env.REACT_APP_DEV_API_URL,
    },
    admin: {
        baseUrl: process.env.REACT_APP_STAG_API_URL, 
    },
};

const ApiUrls = AllEnvApiUrls[process.env.REACT_APP_ENVIRONMENT];
const AdminAuthUrl = `${ApiUrls?.baseUrl}/v1`;

export const URLS = {
    // Auth APIs
    login: `${AdminAuthUrl}/admin/login`,
    twoFactAuth: `${AdminAuthUrl}/admin/email2FA`,
    forgotPassword: `${AdminAuthUrl}/admin/forgot-password`,
    changePassword:`${AdminAuthUrl}/admin/change-password`,
    updatePassword:`${AdminAuthUrl}/admin/update-password`,

    // Migration Balance and Tx APIs
    dash_MGB_Balance: `${AdminAuthUrl}/admin/dash_MGB_Balance`,
    dash_MFORGE_Balance: `${AdminAuthUrl}/admin/dash_MFORGE_Balance`,
    totalBrn_Trfr_cnt_sum: `${AdminAuthUrl}/admin/totalBrn_Trfr_cnt_sum`,
    getAllMigration: `${AdminAuthUrl}/admin/getAllMigration`,

    // News APIs
    uploadImage: `${AdminAuthUrl}/News/uploadImage`,
    postNews: `${AdminAuthUrl}/News/postNews`,
    getNewss: `${AdminAuthUrl}/News/getNewss`,
    getNewsbyId: `${AdminAuthUrl}/News/getNewsbyId`,
    deleteNews: `${AdminAuthUrl}/News/deleteNews`,
    updateNews: `${AdminAuthUrl}/News/updateNews`,

    // Referral APIs
    getAllReferral: `${AdminAuthUrl}/referral/getAllReferral`, 
    changeRefStatus: `${AdminAuthUrl}/referral/changeRefStatus`,
    getSingleReferral:`${AdminAuthUrl}/referral/getSingleRefDetail`,

    // LeadershipMatrix
    getAllLeadership: `${AdminAuthUrl}/leadership-matrix`,
    getSingleLeadership: `${AdminAuthUrl}/leadership-matrix`,

    // leadership rank 
    getLeadershipRank: (page, pageSize)=>`${AdminAuthUrl}/affiliate/getAllRankerList?page=${page}&pageSize=${pageSize}`,

    //Staking/Farming
    getAllStakes: `${AdminAuthUrl}/stake/stakes`,
    getStakeRewards: `${AdminAuthUrl}/stake/stakeRewards`,

    // referal reward
    getReferalReward: (page, size)=>`${AdminAuthUrl}/affiliate/referralReward?page=${page}&pageSize=${size}`,
    getRewardTransaction: (id)=>`${AdminAuthUrl}/affiliate/getRewardTransactions/${id}`,

    // direct and indirect
    getAllUserForDirectIndirect: (page, size)=>`${AdminAuthUrl}/affiliate/getAllUser?page=${page}&pageSize=${size}`,
    getDirectUser: (id, page, size)=>`${AdminAuthUrl}/affiliate/getDirectUser/${id}?page=${page}&pageSize=${size}`,
    getInDirectUser: (id, page, size)=>`${AdminAuthUrl}/affiliate/getIndirectUser/${id}?page=${page}&pageSize=${size}`
};

