import React, { useContext, useEffect, useState } from 'react';
import './Header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosMenu } from "react-icons/io";
import UserContext from '../../ContextApi/UserContext';
import AuthButton from '../../Buttons/Buttons';
import profile from '../../../assets/profile.png';
import logout from '../../../assets/logoutBlue.png';
import PasswordIcon from '../../../assets/PasswordIcon.png';
import { Modal, Button } from 'react-bootstrap';
import crossIcon from '../../../assets/crossIcon.png'

export default function Header() {
    const { setToggleMenu } = useContext(UserContext);
    const location = useLocation();
    const [pathLocaton, setPathLocation] = useState('');
    const navigate = useNavigate();
    const [logoutToggle, setLogoutToggle] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isOpen, setIsopen] = useState(false);

    useEffect(() => {
        switch (location.pathname.split('/')[1]) {
            case "home":
                setPathLocation("Home");
                break;

            case "migration":
                setPathLocation("migration");
                break;

            case "farming":
                setPathLocation("farm");
                break;

            case "affiliate":
                setPathLocation("affiliate");
                break;

            case "vesting":
                setPathLocation("vesting");
                break;

            case "dao":
                setPathLocation("dao");
                break;

            case "news":
                setPathLocation("news");
                break;
            case "changepassword":
                setPathLocation("changepassword");
                break;
        }
        // setPathLocation(location.pathname.split('/')[1]);
    }, [location.pathname]);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleLogoutClick = () => {
        setLogoutToggle(false);
        setIsLogout(true);
    };
    const handlePasswordClick = () => {
        navigate('/changepassword');
    };

    return (
        <>
            <div className='topHeader'>
                <div className="header-container px-3 py-3 d-flex gap-2 justify-content-between w-100">
                    <div className='d-flex align-items-center gap-2'>
                        <span className='sideToggleIcon' onClick={() => setToggleMenu(prev => !prev)}><IoIosMenu fontSize={45} /></span>
                        <h1 className='mb-0'>{pathLocaton}</h1>
                    </div>
                    {/* {location?.pathname?.includes('news') && <AuthButton onClick={() => navigate('/news/add-news')} id='addNewsBtn'>Add a News</AuthButton>} */}
                    {location?.pathname === '/news' && <AuthButton onClick={() => navigate('/news/add-news')} id='addNewsBtn'>Add a News</AuthButton>}

                </div>
                <div className='dropdown position-relative' id='dropdown'>
                    <div className="sorttablebtnn dropdown drop-down-box">
                        <button type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="true" className="border-0 bg-transparent  w-100 h-100">
                            {/* <img src={filterTableIcon} alt="filterTable Icon" /> */}
                            <img src={profile} alt="profileIcon" />
                        </button>


                        <ul className="dropdown-menu filter_drop_box change-pwd" aria-labelledby="dropdownMenu2">
                            <li><button className='profile_btn border-0 bg-transparent d-flex' onClick={handlePasswordClick}>
                                <img src={PasswordIcon} alt='logoutIcon' />
                                <div>Change password</div>
                            </button></li><li><button className='profile_btn border-0 bg-transparent d-flex' onClick={handleLogoutClick}>
                                <img src={logout} alt='logoutIcon' />
                                <div>Logout</div>
                            </button></li>
                        </ul>
                    </div>
                </div>
            </div>

            <Modal
                show={isLogout}
                onHide={() => setIsLogout(false)}
                backdrop="static"
                keyboard={false}
                centered
                className="custom-modal"

            >
                <Modal.Header className="modal-header-custom">
                    <Modal.Title>Confirm Logout ?</Modal.Title>
                    <div className='close_button'>
                        <button type="button" className="custom_btn" onClick={() => setIsLogout(false)}>
                            <img src={crossIcon} alt="Close" className="custom-close-icon" />
                        </button>
                    </div>

                </Modal.Header>
                <Modal.Body className="text-center">Are you sure, you want to logout?</Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <AuthButton onClick={() => setIsLogout(false)} className="custom-button cancel-button">
                        Cancel
                    </AuthButton>
                    <AuthButton onClick={handleLogout} className="custom-button logout-button">
                        Logout
                    </AuthButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}


