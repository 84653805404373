import React, { useEffect, useState } from 'react'
import './ForgotPass.css'
import './Auth.css'
import magnumMataLogo from '../../assets/magnumMetaLogo.png'
import AuthButton from '../Buttons/Buttons'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { URLS } from '../../Utils/app_urls'
import { errorPopup, successPopup } from '../../Utils/ToasterMessage'
import { Spinner } from 'react-bootstrap'


export default function ForgotPassword() {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address.').required('Email is required.'),

        }),
        onSubmit: async (userDetails) => {
            setLoader(true)
            await axios.post(URLS.forgotPassword, userDetails)
                .then(res => {
                    successPopup(res?.data?.message)    
                    setLoader(false)
                })
                .catch(err => {
                    errorPopup(err?.response?.data?.message)
                    setLoader(false)
                })
        }
    });

  useEffect(()=>{

  },[])
  
    return (
        <>
            <div className="login_main">
                <div className="login_container">
                    <img src={magnumMataLogo} alt="magnumMata Logo" />
                    <div className="forgot_container inputContainer mt-4">
                        <h1>Forgot Password</h1>
                        <form className='d-flex flex-column forgot_box' onSubmit={formik.handleSubmit}>
                            <label className='mb-2' htmlFor="email">Username</label>
                            <input id='email' type="email" placeholder='Enter Your email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.email && formik.touched.email && (
                                <span className="input-feedback text-danger auth-error text-start">{formik.errors.email}</span>
                            )}
                            <p className='m-0 text-end forgot_passtext'><span onClick={() => {
                                localStorage.removeItem("acessToken")
                                navigate('/login')

                                
                                }}>Back to Login.</span></p>
                            <AuthButton className='mt-4' type='submit'>
                                {loader && <Spinner animation='border' />}
                                Submit
                            </AuthButton>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
